import React, { useRef, useState, useEffect } from 'react'

import Form from '@/components/Form'
import SimpleInput from '@/components/Form/SimpleInput'
import Checkbox from '@/components/Form/Checkbox'
import MultiSelect from '@/components/Form/MultiSelect'

import { CloseIcon } from '@/components/icons'
import Button from '@/components/Button'

import StudentAutoComplete from '@/components/StudentAutoComplete'

export default function AddStudentToSessionForm({
  course,
  initialData = null,
  formErrors = {},
  editStudent = true,
  onSubmit = () => {},
  onDelete = () => {},
  deleteable = false,
  ...rest
}) {
  const [paymentMethods, setPaymentMethods] = useState([
    { value: 'Cash', selected: false },
    { value: 'Credit/Debit Card', selected: false },
    { value: 'Check', selected: false },
  ])

  const [statuses, setStatuses] = useState([
    { value: 'Incomplete', className: 'yellow', selected: false },
    { value: 'Pass', className: 'green', selected: false },
    { value: 'Fail', className: 'red', selected: false },
    { value: 'No show', className: 'red', selected: false },
    { value: 'Audit', className: 'purple', selected: false },
  ])

  useEffect(() => {
    if (initialData) {
      if (initialData.paymentMethod) {
        const paymentMethod = initialData.paymentMethod
          ? initialData.paymentMethod.toLowerCase()
          : ''
        let newPaymentMethods = paymentMethods.map(pm => {
          pm.selected = pm.value.toLowerCase() === paymentMethod
          return pm
        })
        setPaymentMethods(newPaymentMethods)
      }
      if (initialData.status) {
        const status = initialData.status
          ? initialData.status.toLowerCase()
          : 'null'
        let newStatuses = statuses.map(s => {
          s.selected = s.value.toLowerCase() === status
          return s
        })
        setStatuses(newStatuses)
      }
    }
  }, [initialData])

  const refs = {
    studentId: useRef(''),
    fee: useRef(''),
    paymentAmount: useRef(''),
    comments: useRef(''),
    submitted: useRef(false),
  }

  const context = { initialData, refs, formErrors }

  const getData = () => {
    const data = {
      paymentMethod: paymentMethods.find(p => p.selected)
        ? paymentMethods.find(p => p.selected).value
        : '',
      status: statuses.find(p => p.selected)
        ? statuses.find(p => p.selected).value
        : '',
      comments: '',
    }
    Object.keys(refs).forEach(key => {
      if (refs[key].current.value) data[key] = refs[key].current.value.trim()
    })
    return data
  }

  return (
    <Form
      onSubmit={() => onSubmit(getData())}
      submitText={initialData ? 'Save' : 'Add Student'}
      extraButtons={
        deleteable &&
        initialData && (
          <Button
            key="delete"
            text="Remove Student"
            className="red"
            onClick={() => onDelete(getData())}
          >
            <CloseIcon color="#fff" />
          </Button>
        )
      }
      {...rest}
    >
      <div className="row">
        <div className="col">
          <StudentAutoComplete
            label="Student"
            slug="studentId"
            context={context}
            readOnly={!editStudent}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleInput
            type="text"
            slug="comments"
            label="Comments"
            context={context}
          />
        </div>
      </div>
      <h2>Payment Details</h2>
      <div className="row">
        <div className="col">
          <SimpleInput
            type="number"
            className="price"
            step=".01"
            label="Amount Paid"
            slug="paymentAmount"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput
            type="number"
            className="price"
            step=".01"
            label="Course Cost"
            readOnly={true}
            slug="fee"
            defaultValue={course ? course.fee : 0}
            context={context}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <MultiSelect
            label="Payment Method"
            slug="paymentMethod"
            multi={false}
            context={context}
            options={paymentMethods}
            onChange={setPaymentMethods}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <MultiSelect
            label="Status"
            slug="status"
            multi={false}
            context={context}
            options={statuses}
            onChange={setStatuses}
          />
        </div>
        <div className="col">
          <Checkbox label="Submitted" slug="submitted" context={context} />
        </div>
      </div>
    </Form>
  )
}
