import React, { useRef, useState, useEffect, useMemo } from 'react'

import { getStudentFormData } from '@/services/LocalStorage'

import Button from '@/components/Button'
import { AddIcon } from '@/components/icons'

import Form from '@/components/Form'
import MultiSelect from '@/components/Form/MultiSelect'
import SimpleInput from '@/components/Form/SimpleInput'
import GenderSelect from '@/components/Form/GenderSelect'
import DateInput from '@/components/Form/DateInput'
import HiddenInput from '@/components/Form/HiddenInput'
import PhoneNumberInput from '@/components/Form/PhoneNumberInput'
import SSNInput from '@/components/Form/SSNInput'
import StateSelect from '@/components/Form/StateSelect'

import StudentNewSearch from '@/components/StudentNewSearch'

import { getAll as getCourseInterests } from '@/services/CourseInterests'

import { stripPhone, stripSSN } from '@/shared/util'

export default function StudentForm({
  initialData = null,
  remember = false,
  formErrors = {},
  onSubmit = () => {},
  onCancel = () => {},
  onNext = () => {},
  onSelectOtherStudent = () => {},
  updateLeadInfo = false,
  showAutoComplete = false,
  ...rest
}) {
  if (remember) initialData = getStudentFormData()

  const refs = {
    id: useRef(''),
    first_name: useRef(''),
    middle_name: useRef(''),
    last_name: useRef(''),
    preferred_name: useRef(''),
    license_number: useRef(''),
    license_expiration: useRef(''),
    current_firm: useRef(''),
    birthdate: useRef(''),
    sex: useRef(''),
    ssn: useRef(''),
    address: useRef(''),
    address_2: useRef(''),
    city: useRef(''),
    state: useRef(''),
    zip: useRef(''),
    phone_1: useRef(''),
    phone_2: useRef(''),
    email: useRef(''),
    comments: useRef(''),
  }

  const [courseInterests, updateCourseInterests] = useState([])

  // const context = { initialData, refs, formErrors }
  const context = useMemo(
    () => ({
      initialData,
      refs,
      formErrors,
    }),
    [formErrors],
  )

  useEffect(() => {
    if (!courseInterests.length && updateLeadInfo)
      getCourseInterests().then(({ courseInterests }) =>
        updateCourseInterests(
          courseInterests.map(int => {
            int.value = int.id
            int.label = int.name
            int.selected = initialData.lead
              ? initialData.lead.courseInterests.indexOf(int.id) !== -1
              : false
            return int
          }),
        ),
      )
  }, [initialData])

  const getData = () => {
    const student = {}
    Object.keys(refs).forEach(key => {
      if (
        refs[key].current.value ||
        initialData[key] ||
        !initialData ||
        !initialData.id
      )
        student[key] = refs[key].current.value
          ? refs[key].current.value.trim()
          : ''
    })
    // submit unformatted SSN
    if (student.hasOwnProperty('ssn')) student.ssn = stripSSN(student.ssn)
    // submit unformatted phone numbers
    if (student.hasOwnProperty('phone_1'))
      student.phone_1 = stripPhone(student.phone_1)
    if (student.hasOwnProperty('phone_2'))
      student.phone_2 = stripPhone(student.phone_2)
    student.courseInterests = courseInterests
      .filter(({ selected }) => selected)
      .map(({ value }) => value)
    return student
  }

  const submit = () => {
    onSubmit(getData())
  }
  const cancel = () => {
    onCancel(getData())
  }

  const [forceClearLastName, setForceClearlastName] = useState(undefined)

  const clear = () => {
    Object.keys(refs).forEach(key => {
      let input = refs[key].current
      if (
        input.type === 'text' ||
        input.type === 'textarea' ||
        input.type === 'date'
      )
        refs[key].current.value = ''
    })

    setForceClearlastName(x => !x)
    updateCourseInterests(
      courseInterests.map(interest => {
        interest.selected = false
        return interest
      }),
    )
  }

  return (
    <Form
      id="studentForm"
      clearable={!initialData.id}
      onSubmit={submit}
      onCancel={cancel}
      onClear={clear}
      extraButtons={
        !initialData.id && [
          [
            <Button
              key="add"
              className="green"
              text="Save and add to session"
              onClick={() => onNext(getData())}
            >
              <AddIcon />
            </Button>,
            3,
          ],
        ]
      }
      {...rest}
    >
      <div className="row">
        <div className="col">
          {initialData && initialData.id && (
            <HiddenInput slug="id" type="number" context={context} />
          )}
          <SimpleInput
            label="Student Name"
            slug="first_name"
            placeholder="First Name"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput
            slug="middle_name"
            placeholder="Middle Initial"
            context={context}
          />
        </div>
        <div className="col">
          {!showAutoComplete && (
            <SimpleInput
              slug="last_name"
              placeholder="Last Name"
              context={context}
            />
          )}
          {showAutoComplete && (
            <div className="relative-inline-block">
              {/* <div style={{ position: 'relative', display: 'inline-block' }}> */}
              <StudentNewSearch
                forceClear={forceClearLastName}
                placeholder="Last Name"
                onChange={lastName => {
                  refs.last_name.current = { value: lastName }
                }}
                onSelect={onSelectOtherStudent}
              ></StudentNewSearch>
            </div>
          )}
        </div>
        <div className="col">
          <SimpleInput
            label="Preferred Name"
            slug="preferred_name"
            context={context}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <SimpleInput
            label="License Number"
            slug="license_number"
            context={context}
          />
        </div>
        <div className="col">
          <DateInput
            label="License Expiration"
            slug="license_expiration"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput label="Employer" slug="current_firm" context={context} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <DateInput label="Date of Birth" slug="birthdate" context={context} />
        </div>
        <div className="col">
          <GenderSelect slug="sex" context={context} />
        </div>
        <div className="col">
          <SSNInput slug="ssn" context={context} />
        </div>
      </div>
      <h3>Contact Information</h3>
      <div className="row">
        <div className="col">
          <SimpleInput
            label="Address 1"
            slug="address"
            placeholder="Street"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput
            label="Address 2"
            slug="address_2"
            placeholder="Apt/Suite/Unit"
            context={context}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleInput label="City" slug="city" context={context} />
        </div>
        <div className="col">
          <StateSelect slug="state" context={context} />
        </div>
        <div className="col">
          <SimpleInput label="ZIP" slug="zip" context={context} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <PhoneNumberInput label="Phone 1" slug="phone_1" context={context} />
        </div>
        <div className="col">
          <PhoneNumberInput label="Phone 2" slug="phone_2" context={context} />
        </div>
        <div className="col">
          <SimpleInput label="Email" slug="email" context={context} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleInput
            label="Comments/Notes"
            slug="comments"
            context={context}
          />
        </div>
      </div>
      {updateLeadInfo && courseInterests && (
        <div className="row">
          <div className="col">
            <label htmlFor="course_interests">Course Interests</label>
            <MultiSelect
              name="course_interests"
              options={courseInterests}
              onChange={updateCourseInterests}
              context={context}
            />
          </div>
        </div>
      )}
    </Form>
  )
}
