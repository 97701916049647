import React, { useContext, useRef, useEffect, useState } from 'react'

import { LeadContext } from '@/contexts/Lead'

import Form from '@/components/Form'
import MultiSelect from '@/components/Form/MultiSelect'
import Button from '@/components/Button'

import { CloseIcon } from '@/components/icons'
import StudentAutoComplete from '@/components/StudentAutoComplete'

export default function LeadForm({
  initialData = null,
  title = '',
  formErrors = {},
  onSubmit = () => {},
  onDelete = () => {},
  ...rest
}) {
  const { tags: courseInterests } = useContext(LeadContext)
  const [selectedCourseInterests, updateSelectedCourseInterests] = useState([])

  useEffect(() => {
    if (courseInterests && courseInterests.length)
      updateSelectedCourseInterests(courseInterests.slice(0))
  }, [courseInterests])

  useEffect(() => {
    if (
      initialData &&
      initialData.courseInterests &&
      initialData.courseInterests.length
    )
      updateSelectedCourseInterests(
        courseInterests.map(ci => {
          let selected = initialData.courseInterests.find(
            initCi => ci.id == initCi,
          )
          return { ...ci, selected }
        }),
      )
  }, [initialData])

  const refs = {
    id: useRef(''),
    studentId: useRef(''),
  }

  const context = { initialData, refs, formErrors }

  const getData = () => {
    const lead = {
      courseInterests: selectedCourseInterests
        .filter(ci => ci.selected)
        .map(ci => ci.id),
    }
    Object.keys(refs).forEach(key => {
      lead[key] = refs[key].current.value ? refs[key].current.value.trim() : ''
    })
    return lead
  }

  return (
    <Form
      id="leadForm"
      onSubmit={() => onSubmit(getData())}
      extraButtons={
        initialData && (
          <Button
            key="delete"
            text="Remove Lead"
            className="red"
            onClick={() => onDelete(getData())}
          >
            <CloseIcon color="#fff" />
          </Button>
        )
      }
      {...rest}
    >
      <div className="row">
        <div className="col">
          <StudentAutoComplete
            label="Student"
            slug="studentId"
            context={context}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          {selectedCourseInterests.length && (
            <MultiSelect
              label="Course Interests"
              options={selectedCourseInterests}
              onChange={updateSelectedCourseInterests}
              context={context}
            />
          )}
        </div>
      </div>
    </Form>
  )
}
