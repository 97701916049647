import React, { useState, useRef, useEffect } from 'react'

import { UploadIcon, DownloadIcon, LoadingIcon } from '@/components/icons'
import Modal from '@/components/Modal'

import FileInput from '@/components/Form/FileInput'

import Button from '@/components/Button'

import { formatDate } from '@/shared/util'

import { getAllStudentDocuments, getDocumentUrl } from '@/services/Documents'

export default function DocumentList({
  studentId,
  formErrors,
  onUpload = () => {},
  updated = null,
  ...rest
}) {
  const [docs, setDocs] = useState([])
  const [loading, setLoading] = useState(false)

  const refs = {
    document: useRef(),
  }

  const context = { refs, formErrors }

  useEffect(() => {
    updated && setLoading(true)
    getAllStudentDocuments(studentId).then(docs => {
      refs.document.current && (refs.document.current.value = '')
      setLoading(false)
      setDocs(docs)
    })
  }, [studentId, updated])

  useEffect(() => {
    if (formErrors) setLoading(false)
  }, [formErrors])

  return (
    <Modal size="small" {...rest}>
      <h2>Upload new document</h2>
      <br />
      <div className="row flex-start">
        <div className="col">
          <FileInput label="New Document" slug="document" context={context} />
        </div>
        <div className="col">
          <Button
            onClick={() => {
              setLoading(true)
              onUpload(refs.document.current.files[0])
            }}
            text="Upload"
            disabled={loading}
            className="blue"
          >
            <UploadIcon />
          </Button>
        </div>
        <div className="col">{loading && <LoadingIcon />}</div>
      </div>
      {formErrors && (
        <div className="row">
          <div className="col">
            {formErrors && <div className="error">{formErrors}</div>}
          </div>
        </div>
      )}
      <hr />
      <h2>Uploaded files</h2>
      <table className="downloads">
        <thead>
          <tr>
            <th>File name</th>
            <th>Date uploaded</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {docs.length ? (
            docs.map(doc => (
              <tr key={doc.id}>
                <td>{doc.fileName.slice(14).replace(/%20/g, ' ')}</td>
                <td>{formatDate(doc.dateUploaded, 'mm/dd/yyyy')}</td>
                <td>
                  <a
                    onClick={() =>
                      window.open(getDocumentUrl(doc.id), '_blank')
                    }
                  >
                    <span>Download</span> <DownloadIcon color="#1b3fea" />
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="center" colSpan="3">
                No documents
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Modal>
  )
}
