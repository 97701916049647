import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import './styles.scss'

const MenuItem = ({ label, path, children }) => (
  <li className="nav-item">
    <NavLink activeClassName="active" to={path}>
      {children}
      {label}
    </NavLink>
  </li>
)

MenuItem.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
}
export default MenuItem
