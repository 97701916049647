import React from 'react'

import DataTable from './index.js'
import { StudentContext } from '@/contexts/Student'

import { formatPhone } from '@/shared/util'

const columns = [
  {
    label: 'Name',
    expression: ({ first_name, middle_name, last_name }) =>
      last_name + ', ' + first_name + ' ' + (middle_name || ''),
    sortKey: 'last_name',
    link: ({ id }) => '/students/' + id,
  },
  {
    label: 'License #',
    expression: ({ license_number }) => license_number,
    sortKey: 'license_number',
  },
  {
    label: 'Address',
    expression: ({ address }) => address,
    sortKey: 'address',
  },
  {
    label: 'Phone #',
    expression: ({ phone_1, phone_2 }) => formatPhone(phone_1 || phone_2 || ''),
    wrap: false,
  },
  {
    label: 'Email',
    expression: ({ email }) => email,
    sortKey: 'email',
  },
  {
    label: 'Comments',
    expression: ({ comments }) => {
      if (!comments) return ''
      if (comments.length <= 30) return comments
      return comments.slice(0, 30) + '...'
    },
    sortKey: '',
  },
]

export default props => (
  <DataTable
    editable={true}
    context={StudentContext}
    columns={columns}
    name="StudentTable"
    pagerOptions={[50]}
    {...props}
  />
)
