import { post, get } from './index.js'

const URL = process.env.API_URL + 'api/documents'

export const uploadSessionDocument = (sessionId, document) => {
  let data = new FormData()
  data.append('document', document)
  return post(URL + '/sessions/' + sessionId, data, {
    'Content-Type': 'multipart/form-data',
  })
}

export const getAllSessionDocuments = sessionId =>
  get(URL + '/sessions/' + sessionId).then(({ data: { documents } }) =>
    documents.reverse(),
  )

export const uploadStudentDocument = (studentId, document) => {
  let data = new FormData()
  data.append('document', document)
  return post(URL + '/students/' + studentId, data, {
    'Content-Type': 'multipart/form-data',
  })
}

export const getAllStudentDocuments = studentId =>
  get(URL + '/students/' + studentId).then(({ data: { documents } }) =>
    documents.reverse(),
  )

export const uploadStudentSessionDocument = (
  studentId,
  sessionId,
  document,
) => {
  let data = new FormData()
  data.append('document', document)
  return post(`${URL}/student-sessions/${studentId}/${sessionId}`, data, {
    'Content-Type': 'multipart/form-data',
  })
}

export const getAllStudentSessionDocuments = (studentId, sessionId) =>
  get(
    `${URL}/student-sessions/${studentId}/${sessionId}`,
  ).then(({ data: { documents } }) => documents.reverse())

export const getDocumentUrl = id => URL + '/' + id
