import { post } from './index'

import { saveToken, removeToken } from './LocalStorage'

const URL = process.env.API_URL + 'api/auth'

export const logIn = ({ username, password }) =>
  post(URL + '/login', {
    username,
    password,
  }).then(response => saveToken(response.data.token))

export const logOut = () => {
  removeToken()
  location.reload()
}

export const sendPasswordResetEmail = ({ email }) =>
  post(URL + '/forgot', { email })

export const resetPassword = ({ password, confirmPassword, resetToken }) =>
  post(URL + '/reset', { password, confirmPassword, resetToken })
