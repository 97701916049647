import React from 'react'

import { getOnSiteSessions, search } from '@/services/Sessions'

import DataTableProvider from './DataTable'

const SessionContext = React.createContext([[], () => {}])

const SessionProvider = ({ children }) =>
  DataTableProvider({
    context: SessionContext,
    getAll: getOnSiteSessions,
    search,
    processResponse: ({ count, sessions }) => ({
      count,
      data: sessions,
    }),
    children,
  })

export { SessionContext, SessionProvider }
