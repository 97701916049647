import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

export default ({
  text,
  children,
  to,
  onClick = () => {},
  className = '',
  ...rest
}) =>
  to ? (
    <Link className={className + ' button'} to={to}>
      {text}
      {children}
    </Link>
  ) : (
    <button
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
      className={className}
      {...rest}
    >
      {text}
      {children}
    </button>
  )
