import React from 'react'
import { useTableManager } from '@/shared/tableUtil'
import { getById } from '@/services/Sessions'

const SessionRosterContext = React.createContext([[], () => {}])

const SessionRosterProvider = ({ sessionId, children }) => {
  const refreshData = ({
    currentPage,
    rowsPerPage,
    sortKey,
    sortDirection,
  }) => {
    return getById(
      sessionId,
      currentPage,
      rowsPerPage,
      sortKey || undefined, // defers to default set in /services/Sessions#getById
      sortDirection || undefined, // defers to default set in /services/Sessions#getById
    ).then(({ count, students }) => ({ count, data: students }))
  }

  let tableManager = useTableManager(refreshData)

  return (
    <SessionRosterContext.Provider value={tableManager}>
      {children}
    </SessionRosterContext.Provider>
  )
}

export { SessionRosterContext, SessionRosterProvider }
