import React from 'react'
import { useHistory } from 'react-router-dom'

const GoBack = () => {
  const history = useHistory()
  return (
    <a style={style} onClick={() => history.goBack()}>
      &lt; Go Back
    </a>
  )
}

const style = {
  cursor: 'pointer',
}
export default GoBack
