import axios from 'axios'

import { getToken } from './LocalStorage'
import { logOut } from './Auth'

const refreshAuthToken = () =>
  (axios.defaults.headers.common['Authorization'] = 'Bearer ' + getToken())

export const paginationHeaders = ({ page, perPage, sort, sortKey }) => ({
  'X-Page': page,
  'X-Per-Page': perPage,
  'X-Order': sort,
  'X-Sort': sortKey,
})

const handleError = error =>
  new Promise((resolve, reject) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.token
    )
      logOut()
    reject(error.response)
  })

const rejectIfBadStatus = response =>
  new Promise((resolve, reject) => {
    if (response.status >= 200 && response.status < 300) {
      resolve(response)
    } else {
      reject(response)
    }
  })

export const get = (url, headers = {}) =>
  new Promise((resolve, reject) => {
    refreshAuthToken()
    axios
      .get(url, { headers })
      .then(rejectIfBadStatus)
      .then(resolve)
      .catch(handleError)
      .catch(reject)
  })

export const post = (url, body, headers = {}) =>
  new Promise((resolve, reject) => {
    refreshAuthToken()
    axios
      .post(url, body, { headers })
      .then(rejectIfBadStatus)
      .then(resolve)
      .catch(handleError)
      .catch(reject)
  })

export const put = (url, body, headers = {}) =>
  new Promise((resolve, reject) => {
    refreshAuthToken()
    axios
      .put(url, body, { headers })
      .then(rejectIfBadStatus)
      .then(resolve)
      .catch(handleError)
      .catch(reject)
  })

export const apiDelete = url =>
  new Promise((resolve, reject) => {
    refreshAuthToken()
    axios
      .delete(url)
      .then(rejectIfBadStatus)
      .then(resolve)
      .catch(handleError)
      .catch(reject)
  })
