const storage = window.localStorage

const slugs = {
  TOKEN: 'cli_app_token',
  STUDENT_FORM_DATA: 'cli_app_student_form_draft',
}

export const getToken = () => storage.getItem(slugs.TOKEN)
export const saveToken = token => storage.setItem(slugs.TOKEN, token)
export const removeToken = () => storage.removeItem(slugs.TOKEN)

export const getStudentFormData = () => {
  try {
    let data = JSON.parse(storage.getItem(slugs.STUDENT_FORM_DATA))
    if (!data) {
      deleteStudentFormData()
      return {}
    } else {
      return data
    }
  } catch (e) {
    deleteStudentFormData()
    return {}
  }
}
export const saveStudentFormData = data =>
  storage.setItem(slugs.STUDENT_FORM_DATA, JSON.stringify(data))
export const deleteStudentFormData = () =>
  storage.removeItem(slugs.STUDENT_FORM_DATA)
