import React from 'react'

export default function StatusTag({ status, className = '', ...rest }) {
  // title case
  status = status
    ? status
        .split(' ')
        .map(
          word => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(' ')
    : 'N/A'

  // tag colors
  const classes = ['tag'].concat(className ? className.split(' ') : [])
  if (status === 'Pass') classes.push('green')
  if (status === 'Incomplete') classes.push('yellow')
  if (status === 'Audit') classes.push('purple')
  if (status === 'Fail' || status === 'No Show') classes.push('red')

  return (
    <div {...rest} className={classes.join(' ')}>
      {status}
    </div>
  )
}
