import React from 'react'
import { useTableManager } from '@/shared/tableUtil'

export default function DataTableProvider({
  context: Context,
  getAll = () => {},
  search = () => {},
  processResponse = () => {},
  children,
}) {
  const refreshData = ({
    searchTerm,
    currentPage,
    rowsPerPage,
    sortKey,
    sortDirection,
  }) => {
    let operation = searchTerm
      ? search(
          searchTerm,
          currentPage,
          rowsPerPage,
          sortKey || undefined, // defers to default set in services
          sortDirection || undefined, // defers to default set in services
        )
      : getAll(
          currentPage,
          rowsPerPage,
          sortKey || undefined, // defers to default set in services
          sortDirection || undefined, // defers to default set in services
        )

    return operation.then(response => processResponse(response))
  }

  /* DataTable Manager
  {
      data,
      rowsPerPage,
      getTableData,
      totalRows,
      movedToFirstPage,
      loading,
      setLoading,
      setSearchTerm,
      searchTerm,
      setTags,
      tags
    },
  */

  let tableManager = useTableManager(refreshData)

  return <Context.Provider value={tableManager}>{children}</Context.Provider>
}
