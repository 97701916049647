import { paginationHeaders, get, post, put, apiDelete } from './index.js'

const URL = process.env.API_URL + 'api/students'

export const getAll = (page = 1, perPage = 10, sortKey = 'id', sort = 'DESC') =>
  get(URL, paginationHeaders({ page, perPage, sort, sortKey })).then(
    ({ data }) => data,
  )

export const search = (
  query,
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'DESC',
) =>
  post(
    URL + '/search',
    { query },
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const create = student =>
  new Promise((resolve, reject) =>
    post(URL, student)
      .then(response =>
        response.data.created
          ? resolve(response.data.student)
          : reject(response),
      )
      .catch(err => reject(err)),
  )

export const update = student =>
  new Promise((resolve, reject) =>
    put(URL, student)
      .then(response =>
        response.data.updated
          ? resolve(
              response.data.student.length
                ? response.data.student[0]
                : response.data.student,
            )
          : reject(response),
      )
      .catch(err => reject(err)),
  )

export const getById = (
  id,
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'DESC',
) =>
  get(URL + '/' + id, paginationHeaders({ page, perPage, sort, sortKey })).then(
    ({ data }) => data,
  )

export const deleteById = id =>
  apiDelete(URL + '/' + id).then(({ data }) => data)
