import React, { useState, useEffect } from 'react'
import { useTableManager } from '@/shared/tableUtil'
import { getAll, search } from '@/services/Leads'

const LeadContext = React.createContext([[], () => {}])

const LeadProvider = ({ children }) => {
  // const [courseInterests, updateCourseInterests] = useState([])

  const refreshData = ({
    searchTerm,
    currentPage,
    rowsPerPage,
    sortKey,
    sortDirection,
    tags,
  }) => {
    let operation =
      searchTerm || tags.filter(ci => ci.selected).map(ci => ci.value).length
        ? search(
            searchTerm,
            currentPage,
            rowsPerPage,
            sortKey || undefined, // defers to default set in services
            sortDirection || undefined, // defers to default set in services
            tags ? tags.filter(ci => ci.selected).map(ci => ci.value) : [],
          )
        : getAll(
            currentPage,
            rowsPerPage,
            sortKey || undefined, // defers to default set in services
            sortDirection || undefined, // defers to default set in services
          )

    return operation.then(({ count, courseInterests: tagsSource, leads }) => {
      return { count, data: leads, tagsSource }
    })
  }

  let tableManager = useTableManager(refreshData)

  return (
    <LeadContext.Provider value={tableManager}>{children}</LeadContext.Provider>
  )
}
export { LeadContext, LeadProvider }
