import React from 'react'

import { getAll, search } from '@/services/Students'

import DataTableProvider from './DataTable'

const StudentContext = React.createContext([[], () => {}])

const StudentProvider = ({ children }) =>
  DataTableProvider({
    context: StudentContext,
    getAll,
    search,
    processResponse: ({ count, students }) => ({ count, data: students }),
    children,
  })

export { StudentContext, StudentProvider }
