import React, { useContext } from 'react'

import Button from '@/components/Button'
import { DownloadIcon } from '@/components/icons'

import { LeadContext } from '@/contexts/Lead'

import { getLeadReport } from '@/services/Reports'

export default function ExportButton() {
  let { tags: courseInterests } = useContext(LeadContext)
  return (
    <Button
      className="col"
      text="Export View"
      onClick={() =>
        window.open(
          getLeadReport(
            courseInterests.find(ci => ci.selected)
              ? courseInterests.find(ci => ci.selected).id
              : false,
          ),
          '_blank',
        )
      }
      disabled={
        courseInterests && courseInterests.filter(ci => ci.selected).length > 1
      }
    >
      <DownloadIcon />
    </Button>
  )
}
