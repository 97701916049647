import { paginationHeaders, get, post, put, apiDelete } from './index.js'

const URL = process.env.API_URL + 'api/courses'

export const getAll = (page = 1, perPage = 10, sortKey = 'id', sort = 'ASC') =>
  get(URL, paginationHeaders({ page, perPage, sort, sortKey })).then(
    ({ data }) => data,
  )

export const getCurrentlyOffered = (
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'ASC',
) =>
  get(
    URL + '/currently_offered',
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const getArchive = (
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'ASC',
) =>
  get(
    URL + '/not_currently_offered',
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const getHomeStudies = (
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'ASC',
) =>
  get(
    `${URL}/home_studies`,
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const getOnsite = (
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'ASC',
) =>
  get(
    `${URL}/onsite`,
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const getById = id => get(URL + '/' + id).then(({ data }) => data)

export const searchCurrentlyOffered = (
  query,
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'ASC',
) =>
  post(
    URL + '/search',
    { query, currently_offered: true },
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const searchArchive = (
  query,
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'ASC',
) =>
  post(
    URL + '/search',
    { query, currently_offered: false },
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const create = course =>
  new Promise((resolve, reject) =>
    post(URL, course)
      .then(response =>
        response.data.created
          ? resolve(response.data.course)
          : reject(response),
      )
      .catch(err => reject(err)),
  )

export const update = course =>
  new Promise((resolve, reject) =>
    put(URL, course)
      .then(response =>
        response.data.updated
          ? resolve(response.data.course)
          : reject(response),
      )
      .catch(err => reject(err)),
  )

export const deleteById = id =>
  apiDelete(URL + '/' + id).then(({ data }) => data)
