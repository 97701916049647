import React from 'react'

import { getArchive, searchArchive } from '@/services/Courses'

import DataTableProvider from './DataTable'

const CourseArchiveContext = React.createContext([[], () => {}])

const CourseArchiveProvider = ({ children }) =>
  DataTableProvider({
    context: CourseArchiveContext,
    getAll: getArchive,
    search: searchArchive,
    processResponse: ({ count, courses }) => ({ count, data: courses }),
    children,
  })

export { CourseArchiveContext, CourseArchiveProvider }
