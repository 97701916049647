import React, { Fragment } from 'react'

import DataTable from './index.js'
import { HomeStudySessionContext } from '@/contexts/HomeStudySession.js'

import { getCertificateUrl } from '@/services/Reports'

import StatusTag from '@/components/StatusTag'

import { formatDate } from '@/shared/util'

const columns = [
  {
    label: 'Student Name',
    expression: ({ first_name, middle_name, last_name }) =>
      last_name + ', ' + first_name + ' ' + (middle_name || ''),
    sortKey: 'students.last_name',
  },
  {
    label: 'Course Name',
    expression: ({ course_name }) => course_name,
    link: ({ id }) => '/sessions/' + id,
  },
  {
    label: 'Cost',
    expression: ({ fee }) => fee && fee >= 0 && '$' + fee,
    sortKey: 'fee',
  },
  {
    label: 'Date Purchased',
    expression: ({ began }) => formatDate(began, 'mm/dd/yyyy'),
    sortKey: 'began',
  },
  {
    label: 'Date Completed',
    expression: ({ ended }) => formatDate(ended, 'mm/dd/yyyy'),
    sortKey: 'ended',
  },
  {
    label: 'Status',
    expression: ({ status }) => <StatusTag status={status} />,
  },
  {
    label: 'Certificate',
    expression: ({
      ended,
      first_name,
      middle_name,
      last_name,
      classesTakenId,
      status,
    }) =>
      status &&
      status.toLowerCase() === 'pass' &&
      (first_name !== null || middle_name !== null || last_name !== null) &&
      ended !== null && (
        <Fragment>
          <a
            onClick={() =>
              window.open(getCertificateUrl(classesTakenId, false), 'blank')
            }
          >
            Print
          </a>{' '}
          &middot;{' '}
          <a
            onClick={() =>
              window.open(getCertificateUrl(classesTakenId, true), 'blank')
            }
          >
            Email
          </a>
        </Fragment>
      ),
  },
]

export default props => (
  <DataTable
    context={HomeStudySessionContext}
    columns={columns}
    name="HomeStudySessionTable"
    {...props}
  />
)
