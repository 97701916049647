import React, { useState, useRef } from 'react'
import { Redirect } from 'react-router-dom'

import { resetPassword } from '@/services/Auth'

import Button from '@/components/Button'
import SimpleInput from '@/components/Form/SimpleInput'

export default function Login({ location: { search } }) {
  const [formErrors, setFormErrors] = useState(null)
  const [passwordUpdated, setPasswordUpdated] = useState(false)

  const refs = {
    password: useRef(''),
    confirmPassword: useRef(''),
  }

  let invalidTokenResponse = (
    <div className="block small">
      <div className="row">
        <p className="col">
          This link is only valid for 2 hours. Please send another verification
          email if you still want to change your password.
        </p>
      </div>
      <div className="row center">
        <div className="col">
          <Button
            text="Back to forgot password"
            className="red-wash"
            to="/forgot-password"
          />
        </div>
      </div>
    </div>
  )

  // parse query params
  if (!search) return invalidTokenResponse
  let tokenValid = search.split('isValid=')[1]
  let resetToken = search.split('resetToken=')[1]
  if (!tokenValid || !resetToken) return invalidTokenResponse
  tokenValid = tokenValid.split('&')[0] == 'true'
  resetToken = resetToken.split('&')[0]
  if (!tokenValid || !resetToken) return invalidTokenResponse

  const context = { refs, formErrors }

  const handleSubmit = e => {
    e.preventDefault()
    setFormErrors(null)
    resetPassword({
      password: refs.password.current.value,
      confirmPassword: refs.confirmPassword.current.value,
      resetToken,
    })
      .then(() => setPasswordUpdated(true))
      .catch(({ data }) => data.errors && setFormErrors(data.errors))
  }

  return passwordUpdated ? (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: '/reset-password' },
      }}
    />
  ) : (
    <div className="block small">
      <form>
        <h2>Reset password</h2>
        <div className="row">
          <div className="col">
            <SimpleInput
              type="password"
              label="Password"
              slug="password"
              context={context}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <SimpleInput
              type="password"
              label="Confirm your password"
              slug="confirmPassword"
              context={context}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <Button to="/login" text="Back to login" className="col" />
          <Button
            text="Save new password"
            onClick={handleSubmit}
            className="blue col"
          />
        </div>
      </form>
    </div>
  )
}
