import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Button from '@/components/Button'
import Modal from '@/components/Modal'

import LeadForm from './LeadForm'

import { replace, remove } from '@/services/Leads'
import { getAll } from '@/services/CourseInterests'

import { formatPhone, maskSSN } from '@/shared/util'
import GoBack from '@/shared/GoBack'

export default function Sidebar({ student, ...rest }) {
  const [leadModalVisible, showLeadModal] = useState(false)
  const [courseInterests, setCourseInterests] = useState([])

  const [formErrors, setFormErrors] = useState(null)

  const editLeadInfo = () =>
    getAll()
      .then(({ courseInterests: ci }) => {
        setCourseInterests(ci)
        showLeadModal(true)
      })
      .catch(err => err.data.errors && setFormErrors(err.data.errors))

  const updateLead = courseInterests =>
    replace({ courseInterests, studentId: student.id })
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setFormErrors(err.data.errors))

  const deleteLead = () =>
    remove(student.id)
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setFormErrors(err.data.errors))

  return (
    <div {...rest}>
      <GoBack></GoBack>
      <h2>
        {student.last_name}, {student.first_name}{' '}
        {student.preferred_name && [' (', student.preferred_name, ')']}{' '}
        {student.middle_name || ''}
      </h2>
      <h2 className="subheader">#{student.id}</h2>
      <div className="misc">
        {student.phone_1 && (
          <div>
            <h5>Phone 1</h5>
            <p>{formatPhone(student.phone_1)}</p>
          </div>
        )}
        {student.phone_2 && (
          <div>
            <h5>Phone 2</h5>
            <p>{formatPhone(student.phone_2)}</p>
          </div>
        )}
        {student.email && (
          <div>
            <h5>Email</h5>
            <p>{student.email}</p>
          </div>
        )}
        {student.license_number && (
          <div>
            <h5>License Number</h5>
            <p>{student.license_number}</p>
          </div>
        )}
        {student.ssn && (
          <div>
            <h5>SSN</h5>
            <p>{maskSSN(student.ssn)}</p>
          </div>
        )}
        {(student.address || student.address_2) && (
          <div>
            <h5>Address</h5>
            <p>
              {student.address}
              <br />
              {student.address_2}
            </p>
          </div>
        )}
        {student.comments && (
          <div>
            <h5>Comments</h5>
            <p>{student.comments}</p>
          </div>
        )}
        <div className="leads">
          <hr />
          <h5>Lead info</h5>
          Course interests:{' '}
          {student.lead ? student.lead.courseInterests.length : 0}
          <Button
            className="blue"
            text="Edit lead info"
            onClick={editLeadInfo}
          />
        </div>
      </div>

      <Modal
        size="medium"
        visible={leadModalVisible}
        onClose={() => showLeadModal(false)}
      >
        {leadModalVisible && courseInterests && (
          <LeadForm
            allCourseInterests={courseInterests}
            onSubmit={updateLead}
            onDelete={deleteLead}
            initialData={{
              courseInterests:
                (student.lead && student.lead.courseInterests) || [],
            }}
            formErrors={formErrors}
            onCancel={() => showLeadModal(false)}
          />
        )}
      </Modal>
    </div>
  )
}
