import React, { useContext } from 'react'

import MultiSelect from '@/components/Form/MultiSelect'
import { LeadContext } from '@/contexts/Lead'

export default function CourseInterestSelection({ formErrors, ...rest }) {
  const { tags, setTags } = useContext(LeadContext)

  return (
    <MultiSelect
      id="courseInterests"
      options={tags}
      onChange={setTags}
      context={{ formErrors }}
      {...rest}
    />
  )
}
