import moment from 'moment'
import { useState } from 'react'

export const stripPhone = phone => {
  if (!phone) return ''
  phone = phone.replace(/^\+1/, '')
  return phone.replace(/[\+\(\)\-\s]/g, '')
}

export const formatPhone = phone => {
  phone = stripPhone(phone)
  if (!phone) return ''
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
}

export const stripSSN = ssn => {
  if (!ssn) return ''
  return ssn.replace(/\D/g, '')
}

export const formatSSN = ssn => {
  ssn = stripSSN(ssn)
  if (!ssn) return ''
  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`
}

export const maskSSN = ssn => {
  ssn = stripSSN(ssn)
  if (!ssn) return ''
  return 'XXX-XX-' + ssn.slice(5, 9)
}

export const formatDate = (date, format = 'mm-dd-yyyy') => {
  if (!date) return ''

  date = moment.parseZone(date)

  if (format === 'mm-dd-yyyy') {
    return date.format('MM-DD-YYYY')
  } else if (format === 'mm/dd/yyyy') {
    return date.format('MM/DD/YYYY')
  } else if (format === 'yyyy-mm-dd') {
    return date.format('YYYY-MM-DD')
  } else if (format === 'dd-mm-yyyy') {
    return date.format('DD-MM-YYYY')
  }
}

export const roundDateDownToMonth = date => {
  date = new Date(date)
  let month = date.getMonth()
  let year = date.getFullYear()
  return new Date(year, month, 1)
}

export const roundDateUpToMonth = date => {
  date = new Date(date)
  let month = date.getMonth()
  let year = date.getFullYear()
  return new Date(year, month + 1, 1)
}

export function useSessionStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      // Get from local storage by key
      const item = window.sessionStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return [storedValue, setValue]
}
