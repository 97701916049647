import React, { useRef } from 'react'

import Form from '@/components/Form'
import SimpleInput from '@/components/Form/SimpleInput'
import Checkbox from '@/components/Form/Checkbox'

import {
  SettingsIcon,
  CoursesIcon,
  CoursesIconSmall,
  StudentsIcon,
  HomeStudyIcon,
  SearchIcon,
  DownloadIcon,
  AddIcon,
  DownCaret,
  SaveIcon,
  CloseIcon,
  LeadsIcon,
  TableIcon,
  TableIconSmall,
  EditIcon,
  PrintIcon,
  PrintIconSmall,
  UploadIcon,
} from '@/components/icons'

import '../components/DataTable/styles.scss'

export default function StyleGuide() {
  const refs = {
    text1: useRef(''),
    text2: useRef(''),
    text3: useRef(''),
    price: useRef(0),
    text5: useRef(''),
    text6: useRef(''),
    bool1: useRef(false),
    bool2: useRef(false),
  }

  const initialData = { price: 12.34, bool1: true, bool2: false }
  const context = { refs, initialData }

  return (
    <div className="StyleGuide">
      <div className="container">
        <h1>Style guide</h1>
        <hr />
        <div className="block">
          <h1>Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <hr />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Volutpat
            est velit egestas dui id ornare arcu odio. Sollicitudin tempor id eu
            nisl nunc mi ipsum. Nulla <a href="#">malesuada pellentesque</a>{' '}
            elit eget gravida cum sociis natoque penatibus. Augue neque gravida
            in fermentum et sollicitudin. Aliquet nibh praesent tristique magna
            sit. Elementum curabitur vitae nunc sed velit{' '}
            <a href="#">dignissim</a>. A diam sollicitudin tempor id eu nisl
            nunc mi ipsum.
          </p>
        </div>
        <hr />
        <Form title="Form Title" clearable={true} className="block">
          <div className="row">
            <div className="col">
              <SimpleInput label="Full-width" slug="text1" context={context} />
            </div>
            <div className="col small">
              <Checkbox label="True" slug="bool1" context={context} />
            </div>
            <div className="col small">
              <Checkbox label="False" slug="bool2" context={context} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <SimpleInput label="Half" slug="text2" context={context} />
            </div>
            <div className="col">
              <SimpleInput label="Half" slug="text3" context={context} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <SimpleInput
                className="price"
                label="Third"
                slug="price4"
                context={context}
              />
            </div>
            <div className="col">
              <SimpleInput label="Third" slug="text5" context={context} />
            </div>
            <div className="col">
              <SimpleInput label="Third" slug="text6" context={context} />
            </div>
          </div>
        </Form>
        <hr />
        <div className="block">
          <div className="tag">Tag</div>
          <div className="tag red">Tag.red</div>
          <div className="tag yellow">Tag.yellow</div>
          <div className="tag green">Tag.green</div>
        </div>
        <hr />
        <div className="block">
          <table>
            <thead>
              <tr>
                <th>Header</th>
                <th>Header</th>
                <th>Header</th>
                <th>Header</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cell</td>
                <td>Long Cell Content</td>
                <td>Very Long Cell Content</td>
                <td>Cell</td>
              </tr>
              <tr>
                <td>Cell</td>
                <td>Long Cell Content</td>
                <td>Very Long Cell Content</td>
                <td>Cell</td>
              </tr>
              <tr>
                <td>Cell</td>
                <td>Long Cell Content</td>
                <td>Very Long Cell Content</td>
                <td>Cell</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div className="block">
          <h2>Icons</h2>
          <ul>
            <li>
              <SettingsIcon /> SettingsIcon
            </li>
            <li>
              <CoursesIcon /> CoursesIcon
            </li>
            <li>
              <CoursesIconSmall /> CoursesIconSmall
            </li>
            <li>
              <StudentsIcon /> StudentsIcon
            </li>
            <li>
              <HomeStudyIcon /> HomeStudyIcon
            </li>
            <li>
              <SearchIcon /> SearchIcon
            </li>
            <li>
              <DownloadIcon /> DownloadIcon
            </li>
            <li>
              <AddIcon /> AddIcon
            </li>
            <li>
              <DownCaret /> DownCaret
            </li>
            <li>
              <SaveIcon /> SaveIcon
            </li>
            <li>
              <CloseIcon /> CloseIcon
            </li>
            <li>
              <LeadsIcon /> LeadsIcon
            </li>
            <li>
              <TableIcon /> TableIcon
            </li>
            <li>
              <TableIconSmall /> TableIconSmall
            </li>
            <li>
              <PrintIcon /> PrintIcon
            </li>
            <li>
              <PrintIconSmall /> PrintIconSmall
            </li>
            <li>
              <EditIcon /> EditIcon
            </li>
            <li>
              <UploadIcon /> UploadIcon
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
