import React from 'react'
import { useTableManager } from '@/shared/tableUtil'

import { getByCourseId } from '@/services/Sessions'

const CourseSessionContext = React.createContext([[], () => {}])

const CourseSessionProvider = ({ courseId, children }) => {
  const refreshData = ({
    currentPage,
    rowsPerPage,
    sortKey,
    sortDirection,
  }) => {
    return (
      getByCourseId(
        courseId,
        false,
        currentPage,
        rowsPerPage,
        sortKey || undefined, // defers to default set in /services/Sessions#getById
        sortDirection || undefined, // defers to default set in /services/Sessions#getById
      )
        // set the return data property name
        .then(({ count, sessions }) => ({ count, data: sessions }))
    )
  }

  let tableManager = useTableManager(refreshData)

  return (
    <CourseSessionContext.Provider value={tableManager}>
      {children}
    </CourseSessionContext.Provider>
  )
}

export { CourseSessionContext, CourseSessionProvider }
