// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../shared/assets/svg/caret_down_white.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../shared/assets/svg/caret_up_white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "table{border-collapse:collapse;font-weight:400;width:100%;position:relative;margin:20px 0}table thead{background:#182358;color:#fff;font-weight:bold}table thead th{text-align:left;border:none;white-space:nowrap}table thead th span{background-position:calc(100% - 0.75rem) center;padding-right:2rem;background-repeat:no-repeat}table thead th span.asc{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}table thead th span.desc{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}table thead th:first-child{border-radius:4px 0 0 0}table thead th:last-child{border-radius:0 4px 0 0}table tbody{position:relative}table tbody.loading tr{opacity:0.35}table tbody.loading::after{position:absolute;top:0;bottom:0;left:0;right:0;content:'';background-size:50px 50px;background-position:center;min-height:60px;background-repeat:no-repeat}table tbody tr{border-bottom:1px solid rgba(51,76,197,0.15)}table th,table td{padding:20px}table th .tag,table td .tag{margin:0}.DataTable{background:#fff}.DataTable th.sortable{cursor:pointer}.DataTable td.no-wrap{white-space:nowrap}.DataTable td a{color:#1b3fea;cursor:pointer;border-bottom:1px solid #1b3fea}.pagination{align-items:center}.pagination a{margin:0 1rem;cursor:pointer}.pagination a.disabled{color:#999;border-color:transparent;cursor:default}.pagination select{margin:0;width:4rem;background-color:#fff}\n", ""]);
// Exports
module.exports = exports;
