import React, { useState, useRef } from 'react'

import './styles.scss'

import { sendPasswordResetEmail } from '@/services/Auth'

import Alert from '@/components/Alert'
import Button from '@/components/Button'

import SimpleInput from '@/components/Form/SimpleInput'

export default function Login() {
  const [error, setError] = useState('')
  const [formErrors, setFormErrors] = useState(null)
  const [response, setResponse] = useState('')

  const refs = {
    email: useRef(''),
  }

  const context = { refs, formErrors }

  const handleSubmit = e => {
    e.preventDefault()
    setFormErrors(null)
    setError('')
    sendPasswordResetEmail({
      email: refs.email.current.value.trim(),
    })
      .then(({ data }) => {
        if (data.hasOwnProperty('text')) setResponse(data.text)
      })
      .catch(({ data }) => {
        data.message && setError(data.message)
        data.errors && setFormErrors(data.errors)
      })
  }

  return (
    <div className="block small">
      <form>
        <Alert text={error} onTimeout={() => setError('')} className="red" />
        <h2>Forgot your password?</h2>
        <div className="row">
          <div className="col">
            <SimpleInput label="Email" slug="email" context={context} />
            {response && <p className="success">{response}</p>}
          </div>
        </div>
        <hr />
        <div className="row">
          <Button to="/login" text="Back to login" className="col" />
          <Button
            text="Send email"
            onClick={handleSubmit}
            className="blue col"
          />
        </div>
      </form>
    </div>
  )
}
