import React from 'react'
import { Link } from 'react-router-dom'
export function convertColumn(columns, editable, onEdit) {
  // in case a column has neither sortby or label
  let unNameSequence = 0

  const handleEdit = (e, data) => {
    e.preventDefault()
    onEdit(data)
  }

  // react-table columns
  const rtColumns = []
  for (let col of columns) {
    let colOptions = {
      Header: col.label,
      id: col.sortKey ? col.sortKey : col.label ? col.label : ++unNameSequence,
      access: 'id',
      canSort: !!col.sortKey,
      Cell: x => {
        return col.link ? (
          <Link to={col.link(x.row.original)}>
            {col.expression(x.row.original)}
          </Link>
        ) : (
          col.expression(x.row.original)
        )
      },
    }
    rtColumns.push(colOptions)
  }

  // now take care of editable
  if (editable) {
    rtColumns.push({
      id: 'editable',

      Cell: x => {
        return (
          <a className="edit" onClick={e => handleEdit(e, x.row.original)}>
            Edit
          </a>
        )
      },
    })
  }

  return rtColumns
}
