import React, { Fragment, useContext } from 'react'

import { Link } from 'react-router-dom'

import DataTable from './index.js'
import { SessionContext } from '@/contexts/Session.js'

import { formatDate } from '@/shared/util'

const columns = [
  {
    label: 'Date',
    expression: ({ began, ended }) =>
      formatDate(began, 'mm/dd/yyyy') + ' - ' + formatDate(ended, 'mm/dd/yyyy'),
    sortKey: 'began',
  },
  {
    label: 'Course Name',
    expression: ({ id, course_name, course_type }) => (
      <Fragment>
        <Link to={'/sessions/' + id}>{course_name}</Link>{' '}
        {course_type && course_type.toLowerCase().indexOf('home') >= 0 && (
          <span className="tag small">HS</span>
        )}
      </Fragment>
    ),
  },
  {
    label: 'Cost',
    expression: ({ fee }) => fee !== null && fee >= 0 && '$' + fee,
    sortKey: 'fee',
  },
  {
    label: 'Course #',
    expression: ({ tn_course_number }) => tn_course_number,
  },
  {
    label: 'Hours',
    expression: ({ hours }) => hours,
    sortKey: 'hours',
  },
  {
    label: 'Enrolled Students',
    expression: ({ enrolled_students, available_spots }) =>
      (enrolled_students || '0') +
      (available_spots ? '/' + available_spots : ''),
  },
]

const searchColumns = [
  {
    label: 'Date',
    expression: ({ began, ended }) =>
      formatDate(began, 'mm/dd/yyyy') + ' - ' + formatDate(ended, 'mm/dd/yyyy'),
    sortKey: 'began',
  },
  {
    label: 'Course Name',
    expression: ({ id, course_name, course_type }) => (
      <Fragment>
        <Link to={'/sessions/' + id}>{course_name}</Link>{' '}
        {course_type && course_type.toLowerCase().indexOf('home') >= 0 && (
          <span className="tag small">HS</span>
        )}
      </Fragment>
    ),
  },
  {
    label: 'Cost',
    expression: ({ fee }) => fee !== null && fee >= 0 && '$' + fee,
    sortKey: 'fee',
  },
  {
    label: 'Course #',
    expression: ({ tn_course_number }) => tn_course_number,
  },
  {
    label: 'Hours',
    expression: ({ hours }) => hours,
    sortKey: 'hours',
  },
]

export default function CourseTable(props) {
  const { searchTerm } = useContext(SessionContext)
  return (
    <DataTable
      editable={true}
      context={SessionContext}
      columns={searchTerm ? searchColumns : columns}
      name="SessionTable"
      {...props}
    />
  )
}
