import React, { Fragment, useState, useEffect } from 'react'

import { CloseIcon, AddIcon } from '@/components/icons'

import './styles.scss'

import { search } from '@/services/Students'

export default function StudentNewSearch({
  label = '\u00A0',
  className = '',
  placeholder = '',
  forceClear = undefined,
  onSelect = studentId => {},
  onChange = lastName => {},
}) {
  const [suggestionsVisible, showSuggestions] = useState(true)
  const [suggestions, setSuggestions] = useState([])
  const [query, setQuery] = useState('')
  const [error, setError] = useState(undefined)

  const [student, setStudent] = useState(null)

  useEffect(() => {
    if (forceClear !== undefined) {
      setQuery('')
    }
  }, [forceClear])

  const autocomplete = () => {
    if (query && query.length >= 2) {
      // if (query && query.length >= 2 && !student) {
      search(query)
        .then(({ students }) => {
          setSuggestions(students)
          showSuggestions(true)
        })
        .catch(err => {
          console.log(err)
          setError({
            customMessage: 'An error occurred.',
            err,
          })
        })
    }
  }

  const select = s => {
    setQuery(s.first_name + ' ' + s.last_name)
    setStudent(s)
    onSelect(s)
    showSuggestions(true)
  }

  return (
    <Fragment>
      <label htmlFor="query">{label}</label>
      <div className={className + ' auto ' + (student ? ' green ' : '')}>
        <input
          autoComplete="off"
          className="student-auto"
          placeholder={placeholder}
          name="query"
          type="text"
          value={query}
          onChange={e => {
            setQuery(e.target.value)
            onChange(e.target.value)
          }}
          onKeyDown={e => {
            if (e.key == 'Enter') {
              e.preventDefault()
              showSuggestions(false)
            } else {
              autocomplete()
            }
          }}
        />
        {/* <button
          type="button"
          title="Press Enter or click here to use text entred for last name."
          onClick={() => {
            showSuggestions(false)
          }}
        >
          <AddIcon color="#1b3feabb" />
        </button> */}
      </div>

      {error && <div className="error">{error.customMessage}</div>}
      {query && suggestionsVisible && suggestions && suggestions.length > 0 && (
        <>
          <div>
            <div className="fade-text">Enter to use text entered</div>
            <div className="fade-text">or select an existing student</div>
          </div>
          <ul className="autoSuggestions absolute">
            {suggestions.map(s => (
              <li key={s.id} value={s.id} onClick={() => select(s)}>
                {s.first_name} {s.middle_name} {s.last_name}
              </li>
            ))}
          </ul>
        </>
      )}
    </Fragment>
  )
}
