import React, { useState } from 'react'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { AddIcon, CoursesIconSmall } from '@/components/icons'

import CourseArchiveTable from '@/components/DataTable/CourseArchiveTable'

import CourseForm from '@/scenes/CourseList/CourseForm'
import { CourseArchiveProvider } from '@/contexts/CourseArchive'
import { update, create } from '@/services/Courses'
import CourseSearchBar from './CourseSearchBar'

export default function CourseArchiveList() {
  const [editCourseModalVisible, showEditCourseModal] = useState(false)
  const [newCourseModalVisible, showNewCourseModal] = useState(false)

  const [editCourseErrors, setEditCourseErrors] = useState(null)
  const [newCourseErrors, setNewCourseErrors] = useState(null)

  const [editedCourse, setEditedCourse] = useState(null)

  const updateCourse = course => {
    setEditCourseErrors(null)
    update(course)
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setEditCourseErrors(err.data.errors))
  }
  const createCourse = course => {
    setNewCourseErrors(null)
    create(course)
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setNewCourseErrors(err.data.errors))
  }

  return (
    <CourseArchiveProvider>
      <div className="block">
        <div className="row space-between">
          <div className="col grow">
            <CourseSearchBar />
          </div>
          <Button className="col" text="Current Courses" to="/courses">
            <CoursesIconSmall />
          </Button>
          <Button
            className="col blue"
            text="New Course"
            onClick={() => showNewCourseModal(true)}
          >
            <AddIcon />
          </Button>
        </div>

        <CourseArchiveTable
          onEdit={course => {
            setEditedCourse(course)
            showEditCourseModal(true)
          }}
        />

        <Modal
          size="medium"
          visible={newCourseModalVisible}
          onClose={() => showNewCourseModal(false)}
        >
          <CourseForm
            title="Add Course"
            onSubmit={createCourse}
            onCancel={() => showNewCourseModal(false)}
            formErrors={newCourseErrors}
          />
        </Modal>

        <Modal
          size="medium"
          visible={editCourseModalVisible}
          onClose={() => showEditCourseModal(false)}
        >
          <CourseForm
            title="Edit Course"
            onSubmit={updateCourse}
            initialData={editedCourse}
            onCancel={() => showEditCourseModal(false)}
            formErrors={editCourseErrors}
          />
        </Modal>
      </div>
    </CourseArchiveProvider>
  )
}
