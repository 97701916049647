import React from 'react'

import DataTable from './index.js'
import { LeadContext } from '@/contexts/Lead'

import { formatPhone, formatDate } from '@/shared/util'

const columns = [
  {
    label: 'Date Collected',
    expression: ({ date_collected }) =>
      formatDate(date_collected, 'mm/dd/yyyy'),
    sortKey: 'date_collected',
  },
  {
    label: 'Name',
    expression: ({ student: { first_name, middle_name, last_name } }) =>
      last_name + ', ' + first_name + ' ' + (middle_name || ''),
    link: ({ studentId }) => '/students/' + studentId,
  },
  {
    label: 'License #',
    expression: ({ student: { license_number } }) => license_number,
  },
  {
    label: 'Phone #',
    expression: ({ student: { phone_1, phone_2 } }) =>
      formatPhone(phone_1 || phone_2 || ''),
    wrap: false,
  },
  {
    label: 'Email',
    expression: ({ student: { email } }) => email,
  },
  {
    label: 'Course Name(s)',
    expression: ({ courseInterests }) =>
      courseInterests.map(ci => ci.name).join(', '),
  },
]

export default props => (
  <DataTable
    context={LeadContext}
    columns={columns}
    editable={true}
    name="LeadTable"
    {...props}
  />
)
