import React, { useContext } from 'react'

import SearchBar from '@/components/SearchBar'

import { CourseArchiveContext } from '@/contexts/CourseArchive'

export default function CourseSearchBar() {
  const { setSearchTerm, searchTerm } = useContext(CourseArchiveContext)

  return (
    <SearchBar
      onSubmit={setSearchTerm}
      searchTerm={searchTerm}
      placeholder="Search"
    />
  )
}
