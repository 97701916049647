import React, { useRef, useEffect, useState } from 'react'

import Form from '@/components/Form'
import SelectInput from '@/components/Form/SelectInput'
import SimpleInput from '@/components/Form/SimpleInput'
import MultiSelect from '@/components/Form/MultiSelect'
import DateInput from '@/components/Form/DateInput'

import { getCurrentlyOffered } from '@/services/Courses'
import { getByCourseId } from '@/services/Sessions'

import { formatDate } from '@/shared/util'

export default function AddToSessionForm({
  initialData = null,
  formErrors = {},
  onSubmit = () => {},
  onCancel = () => {},
  ...rest
}) {
  const [course, setCourse] = useState(null)
  const [session, setSession] = useState(null)

  const [currentCourses, setCurrentCourses] = useState([])
  const [currentSessions, setCurrentSessions] = useState([])

  const [paymentMethods, setPaymentMethods] = useState([
    { value: 'Cash', selected: false },
    { value: 'Credit/Debit Card', selected: false },
    { value: 'Check', selected: false },
  ])

  const [statuses, setStatuses] = useState([
    { value: 'Incomplete', className: 'yellow', selected: false },
    { value: 'Pass', className: 'green', selected: false },
    { value: 'Fail', className: 'red', selected: false },
    { value: 'No show', className: 'red', selected: false },
    { value: 'Audit', className: 'purple', selected: false },
  ])

  const refs = {
    paymentAmount: useRef(''),
    fee: useRef(''),
    comments: useRef(''),
  }

  const homeStudyRefs = {
    began: useRef(''),
    hours: useRef(''),
  }

  const context = { initialData, refs, formErrors }
  const homeStudyContext = { initialData, refs: homeStudyRefs, formErrors }

  const getData = () => {
    const data = {
      paymentMethod: paymentMethods.find(p => p.selected)
        ? paymentMethods.find(p => p.selected).value
        : '',
      status: statuses.find(p => p.selected)
        ? statuses.find(p => p.selected).value
        : '',
      sessionId: session ? session.id : null,
      fee: session ? session.fee : null,
      comments: '',
    }
    Object.keys(refs).forEach(key => {
      if (refs[key].current.value) data[key] = refs[key].current.value.trim()
    })
    if (
      course &&
      course.course_type &&
      course.course_type.toLowerCase().indexOf('home') !== -1
    ) {
      Object.keys(homeStudyRefs).forEach(key => {
        if (homeStudyRefs[key].current.value)
          data[key] = homeStudyRefs[key].current.value.trim()
      })
      data.courseId = course.id
    }
    return data
  }

  useEffect(() => {
    getCurrentlyOffered(1, 50, 'course_name').then(({ courses }) =>
      setCurrentCourses(
        courses.map(c => {
          c.value = c.id
          c.label = c.course_name
          return c
        }),
      ),
    )
  }, [])

  useEffect(() => {
    if (initialData) {
      if (initialData.paymentMethod) {
        const paymentMethod = initialData.paymentMethod
          ? initialData.paymentMethod.toLowerCase()
          : ''
        let newPaymentMethods = paymentMethods.map(pm => {
          pm.selected = pm.value.toLowerCase() === paymentMethod
          return pm
        })
        setPaymentMethods(newPaymentMethods)
      }
      if (initialData.status) {
        const status = initialData.status
          ? initialData.status.toLowerCase()
          : 'null'
        let newStatuses = statuses.map(s => {
          s.selected = s.value.toLowerCase() === status
          return s
        })
        setStatuses(newStatuses)
      }
    }
  }, [initialData])

  useEffect(() => {
    setSession(null)
    if (course) {
      refs.fee.current.value = course.fee
      if (
        course &&
        course.course_type &&
        course.course_type.toLowerCase().indexOf('home') !== -1
      ) {
        homeStudyRefs.hours.current.value = course.hours
      }
    }
    course &&
      course.id &&
      getByCourseId(course.id, true).then(({ sessions }) =>
        setCurrentSessions(
          sessions.map(s => {
            s.value = s.id
            s.label =
              formatDate(s.began, 'mm/dd/yyyy') +
              ' - ' +
              formatDate(s.ended, 'mm/dd/yyyy')
            return s
          }),
        ),
      )
  }, [course])

  useEffect(() => {
    if (session) refs.fee.current.value = session.fee
  }, [session])

  return (
    <Form
      id="addToCourse"
      onSubmit={() => onSubmit(getData())}
      onCancel={() => onCancel(getData())}
      {...rest}
    >
      <div className="row">
        <div className="col">
          <SelectInput
            label="Select Course"
            slug="course"
            context={context}
            options={currentCourses}
            defaultValue={course ? course.value : ''}
            onChange={setCourse}
          />
        </div>
      </div>
      {course &&
      course.course_type &&
      course.course_type.toLowerCase().indexOf('home') !== -1 ? (
        <div className="row">
          <div className="col">
            <DateInput
              slug="began"
              label="Start Date"
              context={homeStudyContext}
            />
          </div>
          <div className="col">
            <SimpleInput
              slug="hours"
              label="Hours"
              context={homeStudyContext}
            />
          </div>
        </div>
      ) : (
        course &&
        currentSessions && (
          <div className="row">
            <div className="col">
              <SelectInput
                label="Select Session"
                slug="session"
                context={context}
                options={currentSessions}
                onChange={setSession}
                placeholder="Select a session"
              />
            </div>
          </div>
        )
      )}
      <div className="row">
        <div className="col">
          <SimpleInput
            type="text"
            slug="comments"
            label="Comments"
            context={context}
          />
        </div>
      </div>
      <h2>Payment Details</h2>
      <div className="row">
        <div className="col">
          <SimpleInput
            type="number"
            className="price"
            step=".01"
            label="Amount Paid"
            slug="paymentAmount"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput
            type="number"
            className="price"
            step=".01"
            label="Course Cost"
            readOnly={true}
            slug="fee"
            context={context}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <MultiSelect
            label="Payment Method"
            slug="paymentMethod"
            multi={false}
            options={paymentMethods}
            onChange={setPaymentMethods}
            context={context}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <MultiSelect
            label="Status"
            slug="status"
            multi={false}
            context={context}
            options={statuses}
            onChange={setStatuses}
          />
        </div>
      </div>
    </Form>
  )
}
