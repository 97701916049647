import React, { Fragment } from 'react'

import './styles.scss'

export default function MultiSelect({
  options = [],
  onChange = () => {},
  multi = true,
  label = '',
  slug = '',
  context: { formErrors },
  ...rest
}) {
  // format options from possible flat list or simplified value==label format
  options = options.map(o => {
    if (typeof o === 'string') {
      o = {
        value: o,
        label: o,
        selected: false,
      }
    } else {
      if (!o.hasOwnProperty('value')) o.value = o.label
      if (!o.hasOwnProperty('label')) o.label = o.value
      if (!o.hasOwnProperty('selected')) o.selected = false
    }
    return o
  })

  // reverse `selected` property of matching option
  const handleOptionClick = e => {
    e.preventDefault()
    onChange(
      multi
        ? options.map(o => {
            if (o.value == e.target.value) o.selected = !o.selected
            return o
          })
        : options.map(o => {
            o.selected = o.value === e.target.value
            return o
          }),
    )
  }

  return (
    <Fragment>
      <label htmlFor={slug}>{label}</label>
      <div name={slug} className="multiselect" {...rest}>
        {options.map(option => (
          <button
            key={option.value || options.id}
            value={option.value}
            className={`${option.className} ${
              option.selected ? 'selected' : ''
            }`}
            onClick={handleOptionClick}
          >
            {option.label}
          </button>
        ))}
      </div>
      {formErrors && formErrors[slug] && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
    </Fragment>
  )
}
