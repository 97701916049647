import React, { Fragment } from 'react'

import './styles.scss'

export default function SimpleInput({
  label = '\u00A0',
  slug,
  type = 'text',
  context: { refs, formErrors, initialData },
  ...rest
}) {
  return (
    <Fragment>
      <label htmlFor={slug}>{label}</label>
      <input
        autoComplete="off"
        type={type}
        name={slug}
        ref={refs[slug]}
        defaultValue={initialData && initialData[slug] ? initialData[slug] : ''}
        onKeyDown={e => {
          if (e.key == 'Enter') e.preventDefault()
        }}
        {...rest}
      />
      {formErrors && formErrors[slug] && formErrors[slug].msg && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
    </Fragment>
  )
}
