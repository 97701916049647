import React, { Fragment, useState, useEffect } from 'react'

import { formatPhone } from '@/shared/util'

import './styles.scss'

export default function PhoneNumberInput({
  label = '\u00A0',
  slug,
  context: { refs, formErrors, initialData },
  ...rest
}) {
  // establish internal state
  const [value, setValue] = useState('')

  // if initialData changes, reflect that value
  useEffect(() => {
    if (initialData && initialData[slug] !== null)
      setValue(formatPhone(initialData[slug]))
  }, [initialData])

  const handleChange = e => setValue(format(e.target.value.trim()))

  const format = newValue => {
    // if not less than 15 chars and only 0-9, -, and (), don't update value
    if (!/^[()\d\-\s]{0,}$/.test(newValue)) return value

    // if 10 unformatted digits, fully format
    if (/^[\d]{10}$/.test(value))
      newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(
        3,
        6,
      )}-${newValue.slice(6)}`
    // if it starts with a number, add opening parentheses
    if (/^\d{1,}$/.test(newValue)) newValue = '(' + newValue
    // if `(####`, add closing parentheses and space between 3rd and 4th digit
    if (/^\(\d{4,}/.test(newValue))
      newValue = `${newValue.slice(0, 4)}) ${newValue.slice(4)}`
    // if `(###)#`, add space
    if (/^\(\d{3}\)\d/.test(newValue))
      newValue = `${newValue.slice(0, 5)} ${newValue.slice(5)}`
    // if `(###) ####`, add hyphen
    if (/^\(\d{3}\)\s\d{4,}$/.test(newValue))
      newValue = `${newValue.slice(0, 9)}-${newValue.slice(9)}`

    return newValue.slice(0, 14)
  }

  return (
    <Fragment>
      <label htmlFor={slug}>{label}</label>
      <input
        autoComplete="off"
        type="text"
        name={slug}
        ref={refs[slug]}
        onKeyDown={e => {
          if (e.key == 'Enter') e.preventDefault()
        }}
        value={value}
        onChange={handleChange}
        {...rest}
      />
      {formErrors && formErrors[slug] && formErrors[slug].msg && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
    </Fragment>
  )
}
