import React, { Fragment, useState, useEffect } from 'react'

import './styles.scss'

export default function SelectInput({
  label = '\u00A0',
  slug,
  context: { refs, formErrors, initialData },
  options,
  onChange = () => {},
  placeholder,
}) {
  // establish internal state
  const [value, setValue] = useState('')

  // format options from possible flat list or simplified value==label format
  options = options.map(o => {
    if (typeof o === 'string') {
      o = {
        value: o,
        label: o,
      }
    } else {
      if (!o.hasOwnProperty('value')) o.value = o.label
      if (!o.hasOwnProperty('label')) o.label = o.value
    }
    return o
  })

  const handleChange = e => {
    setValue(e.target.value)
    onChange(options.find(o => o.value == e.target.value))
  }

  // if initialData changes, reflect that value
  useEffect(() => {
    setValue(initialData && initialData[slug] ? initialData[slug] : '')
  }, [initialData])

  return (
    <Fragment>
      <label htmlFor={slug}>{label}</label>
      <select
        name={slug}
        ref={refs[slug]}
        onChange={handleChange}
        value={value}
      >
        <option disabled value="">
          {placeholder}
        </option>
        {options.map(o => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
      {formErrors && formErrors[slug] && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
    </Fragment>
  )
}
