import React, { Fragment, useRef, forwardRef, useImperativeHandle } from 'react'
import { DownloadIcon, AddIcon, EditIcon, CloseIcon } from '@/components/icons'
import { Link } from 'react-router-dom'

import DataTable from './index.js'
import { StudentSessionContext } from '@/contexts/StudentSession.js'

import { getCertificateUrl } from '@/services/Reports'

import StatusTag from '@/components/StatusTag'

import { formatDate } from '@/shared/util'

const columns = [
  {
    label: 'Course Name',
    expression: ({
      session: {
        id,
        course: { course_name, course_type },
      },
    }) => (
      <Fragment>
        <Link to={'/sessions/' + id}>
          {course_name.slice(0, 20).trim() +
            (course_name.length > 20 ? '...' : '')}
        </Link>{' '}
        {course_type && course_type.toLowerCase().indexOf('home') >= 0 && (
          <span className="tag small">HS</span>
        )}
      </Fragment>
    ),
  },
  {
    label: '#',
    expression: ({
      session: {
        course: { tn_course_number },
      },
    }) => tn_course_number,
  },
  {
    label: 'Date',
    expression: ({ session: { began, ended } }) =>
      formatDate(began, 'mm/dd/yyyy') + ' - ' + formatDate(ended, 'mm/dd/yyyy'),
    sortKey: 'session.began',
  },
  {
    label: 'Paid',
    expression: ({ paymentAmount }) =>
      paymentAmount && paymentAmount >= 0 && '$' + paymentAmount,
    sortKey: 'session.fee',
  },
  {
    label: 'Owed',
    expression: ({ fee, paymentAmount }) =>
      fee !== null &&
      paymentAmount !== null &&
      fee - paymentAmount >= 0 &&
      '$' + (fee - paymentAmount),
  },
  {
    label: 'Hours',
    expression: ({ session: { hours } }) => hours,
    sortKey: 'session.hours',
  },
  {
    label: 'Status',
    expression: ({ status }) => <StatusTag status={status} />,
  },
]

const StudentSessionTable = forwardRef(
  ({ viewDocuments = () => {}, editStatus = () => {}, ...rest }, ref) => {
    // Fix column for Status.
    columns.filter(c => c.label === 'Status')[0].expression = row => (
      <a onClick={() => editStatus(row)}>
        <StatusTag status={row.status} /> <EditIcon></EditIcon>
      </a>
    )
    const tableRef = useRef()

    useImperativeHandle(ref, () => ({
      refreshTable() {
        tableRef.current.refreshTable()
      },
    }))

    return (
      <>
        <DataTable
          ref={tableRef}
          id="studentSessionTable"
          context={StudentSessionContext}
          columns={columns.concat([
            {
              label: 'Documents',
              expression: ({ status, id, session }) => (
                <a onClick={() => viewDocuments(session, id, status)}>View</a>
              ),
            },
          ])}
          name="StudentSessionTable"
          {...rest}
        />
      </>
    )
  },
)

export default StudentSessionTable
