import React, { Fragment, useEffect, useState } from 'react'

import './styles.scss'

import { formatDate } from '@/shared/util'

export default function DateInput({
  label = '\u00A0',
  slug,
  context: { refs, formErrors, initialData },
  ...rest
}) {
  // establish internal state
  const [value, setValue] = useState(
    initialData && initialData[slug] ? initialData[slug] : '',
  )

  const handleChange = e => setValue(e.target.value)

  // if initialData changes, reflect that value
  // format date into something readable by HTML5 input
  useEffect(() => {
    let date = initialData && initialData[slug] ? initialData[slug] : ''
    setValue(date === '' ? '' : formatDate(date, 'yyyy-mm-dd'))
  }, [initialData])

  return (
    <Fragment>
      <label htmlFor={slug}>{label}</label>
      <input
        autoComplete="off"
        type="date"
        name={slug}
        ref={refs[slug]}
        value={value}
        onKeyDown={e => {
          if (e.key == 'Enter') e.preventDefault()
        }}
        onChange={handleChange}
        {...rest}
      />
      {formErrors && formErrors[slug] && formErrors[slug].msg && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
    </Fragment>
  )
}
