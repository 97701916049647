import React, { useRef } from 'react'

import { SearchIcon, CloseIcon } from '@/components/icons'

import './styles.scss'

export default function SearchBar({
  className = '',
  onSubmit = () => {},
  placeholder = 'Search',
  searchTerm = '',
}) {
  const query = useRef('')

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(query.current.value.trim())
  }

  const clear = () => {
    query.current.value = ''
    query.current.focus()
  }

  return (
    <>
      <form className={className + ' search'} onSubmit={handleSubmit}>
        <input
          autoComplete="off"
          type="text"
          placeholder={placeholder}
          ref={query}
          onKeyDown={e => {
            if (e.key == 'Enter') {
              e.preventDefault()
              handleSubmit(e)
            }
          }}
          defaultValue={searchTerm}
        />
        {query.current.value && (
          <button type="cancel" onClick={clear}>
            <CloseIcon color="#1b3feabb" />
          </button>
        )}
        <button type="submit" onClick={handleSubmit}>
          <SearchIcon color="#1b3fea" />
        </button>
      </form>
    </>
  )
}
