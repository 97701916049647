import React from 'react'

import '@/components/Form/styles.scss'

import RevenueReport from './RevenueReport'
import TrecReport from './TrecReport'
import StudentReport from './StudentReport'

export default () => (
  <div className="block">
    <h1>Reports</h1>
    <hr />
    <RevenueReport />

    <hr />
    <StudentReport />
  </div>
)
