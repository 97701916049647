import { render } from 'react-dom'
import React from 'react'

import App from './containers/App'

const renderApplication = ApplicationComponent =>
  render(<ApplicationComponent />, document.getElementById('⚛'))

// Render the application.
renderApplication(App)
