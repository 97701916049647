import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { AddIcon, TableIconSmall } from '@/components/icons'

import { SessionProvider } from '@/contexts/Session'
import { create, update } from '@/services/Sessions'

import SessionTable from '@/components/DataTable/SessionTable'
import SessionForm from '@/components/Form/SessionForm'
import HomeStudyForm from '@/scenes/SessionDetails/HomeStudyForm'
import SessionSearchBar from './SessionSearchBar'

export default function SessionList() {
  const [newModalVisible, showNewModal] = useState(false)
  const [editModalVisible, showEditModal] = useState(false)

  const [editFormErrors, setEditFormErrors] = useState(null)
  const [newFormErrors, setNewFormErrors] = useState(null)

  const [sessionUpdated, setSessionUpdated] = useState(null)
  const [editedSession, setEditedSession] = useState(null)

  const addSession = session => {
    setNewFormErrors(null)
    create(session)
      .then(({ id }) => setSessionUpdated(id))
      .catch(err => err.data.errors && setNewFormErrors(err.data.errors))
  }

  const updateSession = session => {
    setEditFormErrors(null)
    update(session)
      .then(({ id }) => {
        setEditedSession(null)
        setSessionUpdated(id)
      })
      .catch(err => err.data.errors && setEditFormErrors(err.data.errors))
  }

  return sessionUpdated ? (
    <Redirect
      to={{
        pathname: '/sessions/' + sessionUpdated,
        state: { from: '/sessions' },
      }}
    />
  ) : (
    <SessionProvider>
      <div className="block">
        <div className="row flex-end">
          <div className="col grow">
            <SessionSearchBar />
          </div>
          <Button className="col" to="/courses" text="Edit Courses">
            <TableIconSmall />
          </Button>
          <Button
            text="New Session"
            className="col blue"
            onClick={() => showNewModal(true)}
          >
            <AddIcon />
          </Button>
        </div>

        <SessionTable
          onEdit={session => {
            setEditedSession(session)
            showEditModal(true)
          }}
        />

        <Modal
          size="medium"
          visible={editModalVisible}
          onClose={() => showEditModal(false)}
        >
          {editedSession &&
          editedSession.course_type &&
          editedSession.course_type.toLowerCase().indexOf('home') !== -1 ? (
            <HomeStudyForm
              title="Edit Home Study"
              onSubmit={updateSession}
              onCancel={() => showEditModal(false)}
              initialData={editedSession}
              formErrors={editFormErrors}
              deleteable={false}
            />
          ) : (
            <SessionForm
              title="Edit Session"
              onSubmit={updateSession}
              onCancel={() => showEditModal(false)}
              initialData={editedSession}
              formErrors={editFormErrors}
              deleteable={false}
            />
          )}
        </Modal>

        <Modal
          size="medium"
          visible={newModalVisible}
          onClose={() => showNewModal(false)}
        >
          <SessionForm
            title="Create New Session"
            formErrors={newFormErrors}
            onSubmit={addSession}
            onCancel={() => showNewModal(false)}
            courseEditable={true}
          />
        </Modal>
      </div>
    </SessionProvider>
  )
}
