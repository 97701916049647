import React, { useEffect, useState } from 'react'

import Form from '@/components/Form'
import MultiSelect from '@/components/Form/MultiSelect'
import Button from '@/components/Button'

import { CloseIcon } from '@/components/icons'

export default function LeadForm({
  initialData = null,
  title = '',
  formErrors = {},
  allCourseInterests,
  onSubmit = () => {},
  onDelete = () => {},
  ...rest
}) {
  const [courseInterests, setCourseInterests] = useState([])

  useEffect(() => {
    if (allCourseInterests && allCourseInterests.length)
      setCourseInterests(
        allCourseInterests.map(ci => {
          ci.value = ci.name
          ci.label = ci.name
          return ci
        }),
      )
  }, [allCourseInterests])

  useEffect(() => {
    if (
      initialData &&
      initialData.courseInterests &&
      initialData.courseInterests.length
    )
      setCourseInterests(
        allCourseInterests.map(ci => {
          ci.selected = initialData.courseInterests.indexOf(ci.id) >= 0
          return ci
        }),
      )
  }, [initialData])

  const context = { initialData, formErrors }

  const getData = () =>
    courseInterests.filter(ci => ci.selected).map(ci => ci.id)

  return (
    <Form
      id="leadForm"
      onSubmit={() => onSubmit(getData())}
      {...rest}
      extraButtons={
        initialData && (
          <Button
            key="delete"
            text="Remove Lead"
            className="red"
            onClick={() => onDelete(getData())}
          >
            <CloseIcon color="#fff" />
          </Button>
        )
      }
    >
      <div className="row">
        <div className="col">
          {courseInterests.length >= 1 && (
            <MultiSelect
              label="Course Interests"
              options={courseInterests}
              onChange={setCourseInterests}
              context={context}
            />
          )}
        </div>
      </div>
    </Form>
  )
}
