import React from 'react'

import { CloseIcon } from '@/components/icons'
import './styles.scss'

export default function Modal({
  size = 'large',
  visible = false,
  className = '',
  children,
  onClose = () => {},
}) {
  const handleModalClick = e => {
    if (e.target.tagName == 'DIV' && e.target.className.indexOf('modal') >= 0)
      onClose(e)
  }

  return (
    visible && (
      <div
        className={className + ' modal ' + size}
        onMouseDown={handleModalClick}
      >
        <div className="block">
          <CloseIcon className="close-modal" onClick={onClose} />
          {children}
        </div>
      </div>
    )
  )
}
