import { useSessionStorage } from './util'
import React, { useCallback, useState } from 'react'
import * as _ from 'lodash'

export function useTableState(tableName, defaultState) {
  const [allTableState, saveAllTableState] = useSessionStorage(
    `cliTableState`,
    [{ ...defaultState, name: tableName }],
  )

  let tableStates = allTableState.filter(s => s.name === tableName)

  let tableState =
    tableStates.length > 0
      ? tableStates[0]
      : { ...defaultState, name: tableName }

  const saveTableState = useCallback(state => {
    let newAllTableState = allTableState.filter(s => s.name !== tableName)
    let newAddition = { ...state, name: tableState.name }
    newAllTableState.push(newAddition)
    saveAllTableState(newAllTableState)
  }, [])

  return [tableState, saveTableState]
}

export function useTableManager(refreshData) {
  const [data, updateData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchTerm, updateSearchTerm] = useState('')
  const [tags, updateTags] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [movedToFirstPage, setMovedToFirstPage] = useState(undefined)

  const updateTagsSelect = (tagsSource, tags) => {
    const getUpdatedTag = (sourceTag, tags) => {
      if (tags.filter(t => t.name === sourceTag.name) > 0) {
        const foundTag = tags.filter(t => t.name === sourceTag.name)[0]
        return { ...sourceTag, selected: foundTag.selected }
      } else {
        return { ...sourceTag, selected: false }
      }
    }
    let result = tagsSource
      .map(ts => getUpdatedTag(ts, tags))
      .map(ts => ({
        ...ts,
        value: ts.id,
        label: ts.name,
        selected: ts.selected,
      }))

    return result
  }

  const refresh = refreshParam => {
    // const thisState = state
    return refreshData(refreshParam).then(({ data, count, tagsSource }) => {
      // search does not return tagSources
      if (tagsSource) {
        // update the tags from db
        const updatedTags = updateTagsSelect(tagsSource, refreshParam.tags)
        updateTags(updatedTags)
      } else {
        updateTags(refreshParam.tags)
      }

      updateSearchTerm(refreshParam.searchTerm)
      setTotalRows(count)
      updateData(data)
    })
  }

  //Toggle the MovedToFristPage Flag. This one starts with a default of undefined.
  const forceMoveToFirst = () => {
    setMovedToFirstPage(x => !x)
  }

  const getTableData = useCallback(
    (state, prevState) => {
      setLoading(true)
      let refreshParam = {
        searchTerm: state.searchTerm,
        currentPage: state.pageIndex + 1,
        rowsPerPage: state.pageSize,
        tags: state.tags ? state.tags : [],
      }

      setRowsPerPage(state.pageSize)

      if (state.sortBy.length === 0) {
        refreshParam.sortKey = ''
        refreshParam.sortDirection = ''
      } else {
        refreshParam.sortKey = state.sortBy[0].id
        refreshParam.sortDirection = state.sortBy[0].desc ? 'DESC' : 'ASC'
      }

      // if first load.
      if (!prevState) {
        return refresh(refreshParam).then(setLoading(false))
      }

      //need to reset first page
      if (
        state.pageSize !== prevState.pageSize ||
        !_.isEqual(
          state.sortBy,
          prevState.sortBy || state.tags !== prevState.tags,
        )
      ) {
        // if pageIndex is not zero, moving first will force a refresh
        // no need to load data.
        if (state.pageIndex !== 0) {
          forceMoveToFirst()
          setLoading(false)
          return Promise.resolve(null)
        } else {
          return refresh(refreshParam).then(setLoading(false))
        }
      }
      return refresh(refreshParam).then(setLoading(false))
    },
    [setRowsPerPage],
  )

  const setSearchTerm = term => {
    updateSearchTerm(term)
    forceMoveToFirst()
  }

  const setTags = tags => {
    updateTags(tags)
    forceMoveToFirst()
  }

  return {
    data,
    rowsPerPage,
    getTableData,
    totalRows,
    movedToFirstPage,
    loading,
    setLoading,
    setSearchTerm,
    searchTerm,
    setTags,
    tags,
  }
}
