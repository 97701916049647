import React, { useRef, useEffect, useState } from 'react'

import { getCurrentlyOffered, getById } from '@/services/Courses'

import Form from '@/components/Form'
import SimpleInput from '@/components/Form/SimpleInput'
import SelectInput from '@/components/Form/SelectInput'
import HiddenInput from '@/components/Form/HiddenInput'
import MultiSelect from '@/components/Form/MultiSelect'
import DateInput from '@/components/Form/DateInput'

import StudentAutoComplete from '@/components/StudentAutoComplete'

export default function HomeStudyForm({
  initialData = null,
  formErrors = {},
  onSubmit = () => {},
  editCourse = false,
  editStudent = true,
  ...rest
}) {
  const [course, setCourse] = useState(null)
  const [allCourses, setAllCourses] = useState([])

  const refs = {
    studentId: useRef(''),
    id: useRef(''),
    courseId: useRef(''),
    began: useRef(''),
    fee: useRef(''),
    hours: useRef(''),
    paymentAmount: useRef(''),
    status: useRef(''),
    comments: useRef(''),
  }

  const [paymentMethods, setPaymentMethods] = useState([
    { value: 'Cash', selected: false },
    { value: 'Credit/Debit Card', selected: false },
    { value: 'Check', selected: false },
  ])

  const [statuses, setStatuses] = useState([
    { value: 'Incomplete', className: 'yellow', selected: false },
    { value: 'Pass', className: 'green', selected: false },
    { value: 'Fail', className: 'red', selected: false },
    { value: 'No show', className: 'red', selected: false },
    { value: 'Audit', className: 'purple', selected: false },
  ])

  const context = { initialData, refs, formErrors }

  useEffect(() => {
    if (initialData) {
      if (initialData.paymentMethod) {
        const paymentMethod = initialData.paymentMethod
          ? initialData.paymentMethod.toLowerCase()
          : ''
        let newPaymentMethods = paymentMethods.map(pm => {
          pm.selected = pm.value.toLowerCase() === paymentMethod
          return pm
        })
        setPaymentMethods(newPaymentMethods)
      }
      if (initialData.status) {
        const status = initialData.status
          ? initialData.status.toLowerCase()
          : 'null'
        let newStatuses = statuses.map(s => {
          s.selected = s.value.toLowerCase() === status
          return s
        })
        setStatuses(newStatuses)
      }
      if (initialData.studentId) {
        let { studentId, first_name, last_name } = initialData
        initialData.student = { id: studentId, first_name, last_name }
      }
    }
    if (editCourse) {
      getCurrentlyOffered(1, 50, 'course_name').then(({ courses }) =>
        setAllCourses(
          courses
            .filter(
              c =>
                c.course_type && c.course_type.toLowerCase() === 'home study',
            )
            .map(c => {
              c.value = c.id
              c.label = c.course_name
              return c
            }),
        ),
      )
    } else {
      getById(initialData.courseId).then(course => {
        initialData.course_name = course.course_name
        setCourse(course)
      })
    }
  }, [initialData])

  useEffect(() => {
    if (course) {
      refs.fee.current.value = course.fee
      refs.hours.current.value = course.hours
    }
  }, [course])

  const getData = () => {
    const session = {
      paymentMethod: paymentMethods.find(p => p.selected)
        ? paymentMethods.find(p => p.selected).value
        : null,
      status: statuses.find(p => p.selected)
        ? statuses.find(p => p.selected).value
        : null,
      paymentDate: new Date(),
      sessionId: initialData ? initialData.id : null,
      submitted: false,
      comments: '',
    }
    Object.keys(refs).forEach(key => {
      if (refs[key].current.value) session[key] = refs[key].current.value.trim()
    })
    return session
  }

  const handleSubmit = () => onSubmit(getData())

  return (
    <Form
      id="homeStudyForm"
      clearable={false}
      onSubmit={handleSubmit}
      {...rest}
    >
      <div className="row">
        <div className="col">
          <StudentAutoComplete
            label="Student"
            slug="studentId"
            context={context}
            initialData={initialData}
            readOnly={!editStudent}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          {initialData && initialData.sessionId && (
            <HiddenInput slug="sessionId" type="number" context={context} />
          )}
          {editCourse ? (
            <SelectInput
              label="Select Course"
              placeholder="Select Course"
              slug="courseId"
              context={context}
              options={allCourses}
              defaultValue={''}
              onChange={setCourse}
            />
          ) : (
            <SimpleInput
              label="Select Course"
              placeholder="Select Course"
              slug="course_name"
              context={context}
              disabled
            />
          )}
        </div>
        <div className="col">
          <DateInput slug="began" label="Start Date" context={context} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleInput
            type="number"
            className="price"
            step=".01"
            label="Course Cost"
            slug="fee"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput label="Hours" slug="hours" context={context} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleInput
            type="text"
            slug="comments"
            label="Comments"
            context={context}
          />
        </div>
      </div>
      <h2>Payment Details</h2>
      <div className="row">
        <div className="col">
          <SimpleInput
            type="number"
            className="price"
            step=".01"
            label="Amount Paid"
            slug="paymentAmount"
            context={context}
          />
        </div>
        <div className="col">
          <MultiSelect
            label="Payment Method"
            slug="paymentMethod"
            multi={false}
            options={paymentMethods}
            onChange={setPaymentMethods}
            context={context}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <MultiSelect
            label="Status"
            slug="status"
            multi={false}
            context={context}
            options={statuses}
            onChange={setStatuses}
          />
        </div>
      </div>
    </Form>
  )
}
