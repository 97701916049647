import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Link } from 'react-router-dom'
import { useTable, usePagination, useSortBy } from 'react-table'
import { convertColumn } from './data-table-adapter'
import ReactJson from 'react-json-view'
import { useTableState } from '../../shared/tableUtil'
import _ from 'lodash'

import './styles.scss'
import { search } from '@/services/Sessions'
import { useHistory } from 'react-router'

const DataTable = forwardRef(
  (
    {
      columns = [],
      context,
      editable = false,
      onEdit = () => {},
      name,
      pagerOptions = [50],
      ...rest
    },
    ref,
  ) => {
    const {
      data,
      rowsPerPage,
      getTableData,
      totalRows,
      movedToFirstPage,
      loading,
      setTags,
      tags,
      searchTerm,
    } = useContext(context)

    const history = useHistory()
    const rtColumns = useMemo(() => convertColumn(columns, editable, onEdit), [
      columns,
    ])

    const tableData = data ? data : []

    const [tableState, saveTableState] = useTableState(name, {
      pageIndex: 0,
      pageSize: pagerOptions[0],
      searchTerm: '',
      tags: [],
    })

    const initialLoadRef = useRef(true)
    const prevStateRef = useRef()

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      // Get the state from the instance
      state,
    } = useTable(
      {
        columns: rtColumns,
        data: tableData,
        disableMultiSort: true,
        initialState: tableState,
        manualSortBy: true,
        manualPagination: true,

        pageCount: Math.ceil(totalRows / rowsPerPage),
        useControlledState: state => {
          return useMemo(() => {
            if (!initialLoadRef.current) {
              return { ...state, searchTerm, tags }
            } else {
              // During the first run of the component, searchTerm is null, we read the value from
              // storage. For other runs, we also include search term from teh context.
              return state
            }
          }, [state, searchTerm, tags])
        },
      },
      useSortBy,
      usePagination,
    )

    useImperativeHandle(ref, () => ({
      refreshTable() {
        getTableData(state, undefined)
      },
    }))

    useEffect(() => {
      if (movedToFirstPage === undefined) {
        return
      }
      if (state.pageIndex !== 0) {
        gotoPage(0) // this will trigger a data refresh.
      } else {
        saveTableState(state)
        getTableData(state, prevStateRef.current).then(() => {
          prevStateRef.current = state
          // remember if it is the initial data load.
          initialLoadRef.current = false
        })
      }
    }, [movedToFirstPage])

    let { pageIndex, pageSize } = state

    useEffect(() => {
      // React-Table cause useEffect run twice when click on the sort.
      // the two state are value equal, but not identity equal.
      if (_.isEqual(prevStateRef.current, state)) {
        return
      }
      saveTableState(state)
      getTableData(state, prevStateRef.current).then(() => {
        prevStateRef.current = state
        // remember if it is the initial data load.
        initialLoadRef.current = false
      })
    }, [getTableData, saveTableState, state])

    if (!tableData.length > 0) {
      return (
        <>
          {/* <ReactJson src={state} collapsed={true} /> */}
          <table className="DataTable" {...getTableProps()} {...rest}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className={'desc'}>
                            {' '}
                            {column.render('Header')}
                          </span>
                        ) : (
                          <span className={'asc'}>
                            {' '}
                            {column.render('Header')}
                          </span>
                        )
                      ) : (
                        <span> {column.render('Header')}</span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan={columns.length + (editable ? 1 : 0)}
                  className="center"
                >
                  {loading ? 'Loading...' : 'No data'}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    }

    return (
      <>
        {/* <ReactJson src={state} collapsed={true} />
      <pre>{JSON.stringify(state.tags, null, 2)}</pre> */}
        <table className="DataTable" {...getTableProps()} {...rest}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className={'desc'}>
                          {' '}
                          {column.render('Header')}
                        </span>
                      ) : (
                        <span className={'asc'}>
                          {' '}
                          {column.render('Header')}
                        </span>
                      )
                    ) : (
                      <span> {column.render('Header')}</span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className={data.length > 0 && loading ? 'loading' : ''}
            {...getTableBodyProps()}
          >
            {page.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="row space-between pagination">
          {pagerOptions.length > 1 && (
            <div className="col grow">
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {pagerOptions.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>{' '}
              Per Page
            </div>
          )}{' '}
          {pagerOptions.length <= 1 && (
            <div className="col grow">{pagerOptions[0]} Per Page</div>
          )}
          <div className="col">
            <a
              className={!canPreviousPage || loading ? 'disabled' : ''}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {'<<'}
            </a>
            <a
              className={!canPreviousPage || loading ? 'disabled' : ''}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </a>
            <span>
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <span>
              {' '}
              | Go to page:{' '}
              <input
                type="number"
                value={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
            <a
              className={!canNextPage || loading ? 'disabled' : ''}
              disabled={!canPreviousPage}
              onClick={() => nextPage()}
            >
              Next
            </a>
            <a
              className={!canNextPage || loading ? 'disabled' : ''}
              disabled={!canPreviousPage}
              onClick={() => gotoPage(pageCount - 1)}
            >
              {'>>'}
            </a>
          </div>
        </div>
      </>
    )
  },
)

export default DataTable
