import React, { useRef, useEffect, useState, useContext, Fragment } from 'react'

import { getCurrentlyOffered, getById } from '@/services/Courses'

import { SessionRosterContext } from '@/contexts/SessionRoster'

import Button from '@/components/Button'
import { CloseIcon } from '@/components/icons'
import StudentAutoComplete from '@/components/StudentAutoComplete'

import Form from './index'
import SimpleInput from './SimpleInput'
import SelectInput from './SelectInput'
import HiddenInput from './HiddenInput'
import DateInput from './DateInput'
import MultiSelect from './MultiSelect'

export default function SessionForm({
  initialData = null,
  formErrors = {},
  onSubmit = () => {},
  onDelete = () => {},
  homeStudy = false,
  deleteable = false,
  courseEditable = false,
  studentEditable = true,
  ...rest
}) {
  const [courseOptions, setAllCourses] = useState([])
  const [course, setCourse] = useState(null)

  // get size of roster for deletion
  const { totalRows } = useContext(SessionRosterContext)

  // determine what data to save
  let refs = {
    id: useRef(''), // sessionId
    courseId: useRef(''),
    fee: useRef(''),
    hours: useRef(''),
    began: useRef(''),
  }
  const onLocationRefs = {
    ended: useRef(''),
    schedule: useRef(''),
    teacher: useRef(''),
    available_spots: useRef(''),
  }
  const homeStudyRefs = {
    studentId: useRef(''),
    paymentAmount: useRef(''),
    status: useRef(''),
    comments: useRef(''),
  }
  if (homeStudy) {
    refs = Object.assign(refs, homeStudyRefs)
  } else {
    refs = Object.assign(refs, onLocationRefs)
  }

  // home study states
  const [paymentMethods, setPaymentMethods] = useState([
    { value: 'Cash', selected: false },
    { value: 'Credit/Debit Card', selected: false },
    { value: 'Check', selected: false },
  ])
  const [statuses, setStatuses] = useState([
    { value: 'Incomplete', className: 'yellow', selected: false },
    { value: 'Pass', className: 'green', selected: false },
    { value: 'Fail', className: 'red', selected: false },
    { value: 'No show', className: 'red', selected: false },
    { value: 'Audit', className: 'purple', selected: false },
  ])

  const context = { initialData, refs, formErrors }

  // get initial course data
  useEffect(() => {
    if (courseEditable) {
      getCurrentlyOffered(1, 50, 'course_name').then(({ courses }) => {
        if (homeStudy) {
          courses = courses.filter(
            c => c.course_type && c.course_type.toLowerCase() === 'home study',
          )
        } else {
          courses = courses.filter(
            c => !c.course_type || c.course_type.toLowerCase() !== 'home study',
          )
        }
        setAllCourses(
          courses.map(c => {
            c.value = c.id
            c.label = c.course_name
            return c
          }),
        )
      })
    } else {
      if (initialData && !initialData.course && initialData.courseId)
        getById(initialData.courseId).then(course => {
          initialData.course_name = course.course_name
          setCourse(course)
        })
    }
  }, [])

  // autofill hours, fee, and courseId on course selection
  useEffect(() => {
    if (course) {
      if (refs.courseId.current !== '') refs.courseId.current.value = course.id
      if (refs.fee.current !== '') refs.fee.current.value = course.fee
      if (refs.hours.current !== '') refs.hours.current.value = course.hours
    }
  }, [course])

  if (homeStudy) {
    useEffect(() => {
      if (initialData) {
        // process initial payment method
        if (initialData.paymentMethod) {
          const paymentMethod = initialData.paymentMethod
            ? initialData.paymentMethod.toLowerCase()
            : ''
          let newPaymentMethods = paymentMethods.map(pm => {
            pm.selected = pm.value.toLowerCase() === paymentMethod
            return pm
          })
          setPaymentMethods(newPaymentMethods)
        }
        if (initialData.status) {
          // process initial class status
          const status = initialData.status
            ? initialData.status.toLowerCase()
            : 'null'
          let newStatuses = statuses.map(s => {
            s.selected = s.value.toLowerCase() === status
            return s
          })
          setStatuses(newStatuses)
        }
      }
    }, [initialData])
  }

  const getData = () => {
    const session = {
      sessionId: initialData && initialData.id ? initialData.id : null,
    }
    if (homeStudy) {
      session.paymentMethod = paymentMethods.find(p => p.selected)
        ? paymentMethods.find(p => p.selected).value
        : null
      session.status = statuses.find(p => p.selected)
        ? statuses.find(p => p.selected).value
        : null
      session.paymentDate = new Date()
      session.submitted = false
    }
    Object.keys(refs).forEach(key => {
      if (refs[key].current.value) session[key] = refs[key].current.value.trim()
    })
    return session
  }

  return (
    <Form
      id="sessionForm"
      onSubmit={() => onSubmit(getData())}
      extraButtons={
        deleteable &&
        (homeStudy || totalRows === 0 ? (
          <Button
            key="delete"
            className="red"
            text="Delete Session"
            onClick={onDelete}
          >
            <CloseIcon />
          </Button>
        ) : (
          <Button
            key="delete"
            disabled
            className="red"
            text="Cannot delete a session with students"
          >
            <CloseIcon />
          </Button>
        ))
      }
      {...rest}
    >
      {homeStudy && (
        <div className="row">
          <div className="col">
            <StudentAutoComplete
              label="Student"
              slug="studentId"
              context={context}
              initialData={initialData}
              readOnly={!studentEditable}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          {initialData && initialData.id && (
            <HiddenInput slug="id" type="number" context={context} />
          )}
          {courseEditable ? (
            <SelectInput
              label="Select Course"
              placeholder="Select Course"
              slug="courseId"
              context={context}
              options={courseOptions}
              defaultValue={''}
              onChange={setCourse}
            />
          ) : (
            <SimpleInput
              label="Select Course"
              slug="course_name"
              context={context}
              disabled
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <DateInput slug="began" label="Start Date" context={context} />
        </div>
        {!homeStudy && (
          <div className="col">
            <DateInput slug="ended" label="End Date" context={context} />
          </div>
        )}
        {!homeStudy && (
          <div className="col">
            <SelectInput
              label="Course Schedule"
              slug="schedule"
              options={['Daytime', 'Evening', 'Weekend']}
              context={context}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col">
          <SimpleInput
            type="number"
            className="price"
            step=".01"
            label="Cost"
            slug="fee"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput
            type="number"
            label="Hours"
            slug="hours"
            context={context}
          />
        </div>
        {!homeStudy && (
          <div className="col">
            <SimpleInput
              type="number"
              label="Available Spots"
              slug="available_spots"
              context={context}
            />
          </div>
        )}
      </div>
      {!homeStudy && (
        <div className="row">
          <div className="col">
            <SimpleInput
              label="Teacher"
              slug="teacher"
              placeholder="Teacher"
              context={context}
            />
          </div>
        </div>
      )}
      {homeStudy && (
        <Fragment>
          <h2>Payment Details</h2>
          <div className="row">
            <div className="col">
              <SimpleInput
                type="number"
                className="price"
                step=".01"
                label="Amount Paid"
                slug="paymentAmount"
                context={context}
              />
            </div>
            <div className="col">
              <MultiSelect
                label="Payment Method"
                slug="paymentMethod"
                multi={false}
                options={paymentMethods}
                onChange={setPaymentMethods}
                context={context}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <MultiSelect
                label="Status"
                slug="status"
                multi={false}
                context={context}
                options={statuses}
                onChange={setStatuses}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Form>
  )
}
