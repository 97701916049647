import React, { Fragment, useState, useEffect } from 'react'

import './styles.scss'

export default function Checkbox({
  label = '\u00A0',
  slug,
  context: { refs, formErrors, initialData },
  defaultValue = false,
  onChange = () => {},
  ...rest
}) {
  // establish internal state
  const [value, updateValue] = useState(false)

  // if initialData changes, reflect that value
  useEffect(
    () =>
      updateValue(
        initialData && initialData[slug] ? initialData[slug] : defaultValue,
      ),
    [initialData],
  )

  const handleChange = e => {
    updateValue(e.target.checked)
    onChange(value)
  }

  return (
    <Fragment>
      <label htmlFor={slug}>{label}</label>
      <input type="hidden" value={value} ref={refs[slug]} name={slug} />
      <input
        autoComplete="off"
        type="checkbox"
        name={slug + '-visible'}
        checked={value}
        onChange={handleChange}
        {...rest}
      />
      {formErrors && formErrors[slug] && formErrors[slug].msg && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
    </Fragment>
  )
}
