import { hot } from 'react-hot-loader/root'
import React from 'react'
import Loadable from 'react-loadable'
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom'

import { AuthProvider } from '@/contexts/Auth'

import Loading from '@/components/Loading'
import Header from '@/components/Header'

import { PublicRoute, NoMatchRoute, PrivateRoute } from './routes/index'
import './styles.scss'

const Login = Loadable({
  loader: () => import(/** webpackChunkName: Login */ '@/scenes/Login'),
  loading: Loading,
})
const Logout = Loadable({
  loader: () => import(/** webpackChunkName: LogOut */ '@/scenes/Logout'),
  loading: Loading,
})
const ForgotPassword = Loadable({
  loader: () =>
    import(/** webpackChunkName: ForgotPassword */ '@/scenes/ForgotPassword'),
  loading: Loading,
})
const ResetPassword = Loadable({
  loader: () =>
    import(/** webpackChunkName: ResetPassword */ '@/scenes/ResetPassword'),
  loading: Loading,
})
const StyleGuide = Loadable({
  loader: () =>
    import(/** webpackChunkName: StyleGuide */ '@/scenes/StyleGuide'),
  loading: Loading,
})

const StudentList = Loadable({
  loader: () =>
    import(/** webpackChunkName: StudentList */ '@/scenes/StudentList'),
  loading: Loading,
})
const StudentDetails = Loadable({
  loader: () =>
    import(/** webpackChunkName: StudentDetails */ '@/scenes/StudentDetails'),
  loading: Loading,
})

const LeadList = Loadable({
  loader: () => import(/** webpackChunkName: LeadList */ '@/scenes/LeadList'),
  loading: Loading,
})

const SessionList = Loadable({
  loader: () =>
    import(/** webpackChunkName: SessionList */ '@/scenes/SessionList'),
  loading: Loading,
})
const SessionDetails = Loadable({
  loader: () =>
    import(/** webpackChunkName: SessionDetails */ '@/scenes/SessionDetails'),
  loading: Loading,
})

const HomeStudySessionList = Loadable({
  loader: () =>
    import(
      /** webpackChunkName: HomeStudySessionList */ '@/scenes/HomeStudySessionList'
    ),
  loading: Loading,
})

const CourseList = Loadable({
  loader: () =>
    import(/** webpackChunkName: CourseList */ '@/scenes/CourseList'),
  loading: Loading,
})
const CourseArchiveList = Loadable({
  loader: () =>
    import(
      /** webpackChunkName: CourseArchiveList */ '@/scenes/CourseArchiveList'
    ),
  loading: Loading,
})
const CourseDetails = Loadable({
  loader: () =>
    import(/** webpackChunkName: CourseDetails */ '@/scenes/CourseDetails'),
  loading: Loading,
})

const Reports = Loadable({
  loader: () => import(/** webpackChunkName: Reports */ '@/scenes/Reports'),
  loading: Loading,
})

const App = function() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Header />
          <Switch key="app">
            <PublicRoute component={Login} path="/login" />
            <PublicRoute component={StyleGuide} path="/style" />
            <PublicRoute
              component={ForgotPassword}
              exact
              path="/forgot-password"
            />
            <PublicRoute component={ResetPassword} exact path="/reset" />
            <PrivateRoute component={Logout} exact path="/logout" />

            <PrivateRoute component={StudentList} exact path="/students" />
            <PrivateRoute component={LeadList} exact path="/students/leads" />
            <PrivateRoute
              component={StudentDetails}
              exact
              path="/students/:id"
            />

            <PrivateRoute component={CourseList} exact path="/courses" />
            <PrivateRoute
              component={CourseArchiveList}
              exact
              path="/courses/archive"
            />
            <PrivateRoute component={CourseDetails} exact path="/courses/:id" />

            <PrivateRoute component={SessionList} exact path="/sessions" />
            <PrivateRoute
              component={SessionDetails}
              exact
              path="/sessions/:id"
            />

            <PrivateRoute
              component={HomeStudySessionList}
              exact
              path="/home-studies"
            />

            <PrivateRoute component={Reports} exact path="/reports" />

            <PrivateRoute exact path="/">
              <Redirect to="/students" />
            </PrivateRoute>
            <Route component={NoMatchRoute} path="*" />
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  )
}

export default hot(App)
