import React from 'react'

import DataTable from './index.js'
import { CourseSessionContext } from '@/contexts/CourseSession.js'

import { formatDate } from '@/shared/util'

const startColumns = [
  {
    label: 'Start Date',
    expression: ({ began }) => formatDate(began, 'mm/dd/yyyy'),
    sortKey: 'began',
  },
  {
    label: 'End Date',
    expression: ({ ended }) => formatDate(ended, 'mm/dd/yyyy'),
    sortKey: 'ended',
  },
]

const endColumns = [
  {
    label: 'Cost',
    expression: ({ fee }) => fee !== null && fee >= 0 && '$' + fee,
    sortKey: 'fee',
  },
  {
    label: 'Hours',
    expression: ({ hours }) => hours,
    sortKey: 'hours',
  },
  {
    label: '',
    expression: () => 'View Session',
    link: ({ id }) => '/sessions/' + id,
  },
]

const homeStudyColumns = startColumns.concat(endColumns)

const onSiteColumns = startColumns
  .concat([
    {
      label: 'Schedule',
      expression: ({ schedule }) => schedule,
      sortKey: 'schedule',
    },
  ])
  .concat(endColumns)

export default function CourseSessionTable({ homeStudy, ...rest }) {
  return (
    <DataTable
      context={CourseSessionContext}
      columns={homeStudy ? homeStudyColumns : onSiteColumns}
      name="CourseSessiontable"
      {...rest}
    />
  )
}
