import React, { useState, Fragment, useEffect } from 'react'

import Button from '@/components/Button'
import { DownloadIcon } from '@/components/icons'

import {
  formatDate,
  roundDateDownToMonth,
  roundDateUpToMonth,
} from '@/shared/util'

import { getRevenueReportUrl } from '@/services/Reports'

import '@/components/Form/styles.scss'

export default function RevenueReport() {
  const [startDate, setStartDate] = useState(
    formatDate(roundDateDownToMonth(new Date()), 'yyyy-mm-dd'),
  )
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    setEndDate(
      formatDate(roundDateUpToMonth(startDate + ' 00:00:00'), 'yyyy-mm-dd'),
    )
  }, [startDate])

  return (
    <Fragment>
      <h2>Revenue Report</h2>
      <div className="row">
        <div className="col">
          <h3>Monthly</h3>
        </div>
        <div className="col">
          <label htmlFor="startDate">Start Date</label>
          <input
            autoComplete="off"
            type="date"
            name="startDate"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
          />
        </div>
        <div className="col">
          <label htmlFor="endDate">End Date</label>
          <input
            autoComplete="off"
            type="date"
            name="endDate"
            value={endDate}
            readOnly
            disabled
          />
        </div>
        <div className="col">
          <Button
            text="Download"
            className="col blue"
            onClick={() =>
              window.open(getRevenueReportUrl(startDate), '_blank')
            }
          >
            <DownloadIcon />
          </Button>
        </div>
      </div>
    </Fragment>
  )
}
