import React from 'react'

import Modal from '@/components/Modal'

export default props => (
  <Modal size="small" {...props}>
    <h2>Class files</h2>
    <ul className="downloads">
      <li></li>
    </ul>
  </Modal>
)
