import React from 'react'
import { useTableManager } from '@/shared/tableUtil'
import { getById } from '@/services/Students'

const StudentSessionContext = React.createContext([[], () => {}])

const StudentSessionProvider = ({ studentId, children }) => {
  const refreshData = ({
    currentPage,
    rowsPerPage,
    sortKey,
    sortDirection,
  }) => {
    return getById(
      studentId,
      currentPage,
      rowsPerPage,
      sortKey || undefined, // defers to default set in /services/Students#getById
      sortDirection || undefined, // defers to default set in /services/Students#getById
    ).then(({ count, sessions }) => ({ count, data: sessions }))
  }

  let tableManager = useTableManager(refreshData)

  return (
    <StudentSessionContext.Provider value={tableManager}>
      {children}
    </StudentSessionContext.Provider>
  )
}

export { StudentSessionContext, StudentSessionProvider }
