import React from 'react'

import DataTable from './index.js'
import { CourseArchiveContext } from '@/contexts/CourseArchive'

const columns = [
  {
    label: 'Course Name',
    expression: ({ course_name }) => course_name,
    sortKey: 'course_name',
    link: ({ id }) => '/courses/' + id,
  },
  {
    label: 'Cost',
    expression: ({ fee }) => fee !== null && fee >= 0 && '$' + fee,
    sortKey: 'fee',
  },
  {
    label: 'Course #',
    expression: ({ tn_course_number }) => tn_course_number,
    sortKey: 'tn_course_number',
  },
  {
    label: 'Hours',
    expression: ({ hours }) => hours,
    sortKey: 'hours',
  },
]

export default function CourseTable(props) {
  return (
    <DataTable
      editable={true}
      context={CourseArchiveContext}
      columns={columns}
      name="CourseArchiveTable"
      {...props}
    />
  )
}
