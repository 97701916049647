import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { AddIcon } from '@/components/icons'

import { HomeStudySessionProvider } from '@/contexts/HomeStudySession'
import { createHomeStudy, updateStudentInSession } from '@/services/Sessions'

import HomeStudySessionTable from '@/components/DataTable/HomeStudySessionTable'

import HomeStudyForm from './HomeStudyForm'

export default function HomeStudySessionList() {
  const [newModalVisible, showNewModal] = useState(false)
  const [editModalVisible, showEditModal] = useState(false)

  const [editFormErrors, setEditFormErrors] = useState(null)
  const [newFormErrors, setNewFormErrors] = useState(null)

  const [homeStudyUpdated, setHomeStudyUpdated] = useState(null)
  const [editedHomeStudy, setEditedHomeStudy] = useState(null)

  const addHomeStudy = homeStudy => {
    setNewFormErrors(null)
    createHomeStudy(homeStudy)
      .then(({ id }) => setHomeStudyUpdated(id))
      .catch(err => err.data.errors && setNewFormErrors(err.data.errors))
  }

  const updateHomeStudy = homeStudy => {
    setEditFormErrors(null)
    updateStudentInSession(homeStudy)
      .then(({ sessionId }) => setHomeStudyUpdated(sessionId))
      .catch(err => err.data.errors && setEditFormErrors(err.data.errors))
  }

  return homeStudyUpdated ? (
    <Redirect
      to={{
        pathname: '/sessions/' + homeStudyUpdated,
        state: { from: '/home-studies' },
      }}
    />
  ) : (
    <HomeStudySessionProvider>
      <div className="block">
        <div className="row flex-end">
          <div className="col grow" />
          <Button
            text="New Session"
            className="col blue"
            onClick={() => showNewModal(true)}
          >
            <AddIcon />
          </Button>
        </div>

        <HomeStudySessionTable
          editable={true}
          onEdit={homeStudy => {
            setEditedHomeStudy(homeStudy)
            showEditModal(true)
          }}
        />

        <Modal
          size="medium"
          visible={newModalVisible}
          onClose={() => showNewModal(false)}
        >
          <HomeStudyForm
            title="New Home Study"
            onSubmit={addHomeStudy}
            editCourse={true}
            onCancel={() => showNewModal(false)}
            formErrors={newFormErrors}
          />
        </Modal>

        <Modal
          size="medium"
          visible={editModalVisible}
          onClose={() => showEditModal(false)}
        >
          <HomeStudyForm
            title="Edit Home Study"
            onSubmit={updateHomeStudy}
            initialData={editedHomeStudy}
            editCourse={false}
            editStudent={false}
            onCancel={() => showEditModal(false)}
            formErrors={editFormErrors}
          />
        </Modal>
      </div>
    </HomeStudySessionProvider>
  )
}
