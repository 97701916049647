import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import './styles.scss'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { AddIcon, DownloadIcon, EditIcon } from '@/components/icons'

import SessionForm from '@/components/Form/SessionForm'
import CourseForm from '@/scenes/CourseList/CourseForm'

import DownloadList from './DownloadList'

import CourseSessionTable from '@/components/DataTable/CourseSessionTable'
import { CourseSessionProvider } from '@/contexts/CourseSession'

import { create } from '@/services/Sessions'
import { update, getById, deleteById } from '@/services/Courses'
import GoBack from '@/shared/GoBack'

export default function CourseDetails({
  match: {
    params: { id },
  },
}) {
  const [course, setCourse] = useState(null)

  const [newFormErrors, setNewFormErrors] = useState(null)
  const [editCourseFormErrors, setEditCourseFormErrors] = useState(null)

  const [newModalVisible, showNewModal] = useState(false)
  const [editCourseModalVisible, showEditCourseModal] = useState(false)
  const [downloadModalVisible, showDownloadModal] = useState(false)

  const [sessionCreated, setSessionCreated] = useState(null)
  const [courseDeleted, setCourseDeleted] = useState(null)

  useEffect(() => {
    getById(id).then(course => setCourse(course))
  }, [id])

  const addSession = session => {
    session.courseId = id
    setNewFormErrors(null)
    create(session)
      .then(({ id }) => setSessionCreated(id))
      .catch(err => err.data.errors && setNewFormErrors(err.data.errors))
  }

  const saveCourse = course => {
    setEditCourseFormErrors(null)
    update(course)
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setEditCourseFormErrors(err.data.errors))
  }

  const deleteCourse = () =>
    deleteById(id)
      .then(() => setCourseDeleted(true))
      .catch(err => err.data.errors && setEditCourseFormErrors(err.data.errors))

  if (!course) {
    return <p>Loading...</p>
  } else if (sessionCreated) {
    return (
      <Redirect
        to={{
          pathname: '/sessions/' + sessionCreated,
          state: { from: '/courses/' + id },
        }}
      />
    )
  } else if (courseDeleted) {
    return (
      <Redirect
        to={{
          pathname: '/courses',
          state: { from: '/courses/' + id },
        }}
      />
    )
  } else {
    return (
      <CourseSessionProvider courseId={id}>
        <div className="block">
          <div className="row">
            <div className="col">
              <GoBack></GoBack>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <h1>{course.course_name}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row flex-start delineated">
                    {course.tn_course_number && (
                      <div className="col">#{course.tn_course_number}</div>
                    )}
                    {course.course_type && (
                      <div className="col">{course.course_type}</div>
                    )}
                    <div className="col">${course.fee}</div>
                    <div className="col">{course.hours} hours</div>
                    {course.course_type &&
                      course.course_type.toLowerCase().indexOf('home') === -1 &&
                      course.available_spots > 0 && (
                        <div className="col">
                          Class size: {course.available_spots}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <Button
              onClick={() => showDownloadModal(true)}
              className="col"
              text="Download"
            >
              <DownloadIcon />
            </Button>
            <Button
              onClick={() => showEditCourseModal(true)}
              className="col"
              text="Edit"
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => showNewModal(true)}
              className="col blue"
              text="New Session"
            >
              <AddIcon />
            </Button>
          </div>

          <div className="row">
            <div className="col">
              <CourseSessionTable
                homeStudy={
                  course.course_type &&
                  course.course_type.toLowerCase().indexOf('home') !== -1
                }
              />
            </div>
          </div>

          <Modal
            size="medium"
            visible={newModalVisible}
            onClose={() => showNewModal(false)}
          >
            <SessionForm
              title="Create New Session"
              onSubmit={addSession}
              initialData={course}
              courseEditable={false}
              onCancel={() => showNewModal(false)}
              formErrors={newFormErrors}
            />
          </Modal>

          <Modal
            size="medium"
            visible={editCourseModalVisible}
            onClose={() => showEditCourseModal(false)}
          >
            <CourseForm
              title="Edit Course"
              onSubmit={saveCourse}
              initialData={course}
              onCancel={() => showEditCourseModal(false)}
              formErrors={editCourseFormErrors}
              deleteable={true}
              onDelete={deleteCourse}
            />
          </Modal>

          <DownloadList
            visible={downloadModalVisible}
            onClose={() => showDownloadModal(false)}
          />
        </div>
      </CourseSessionProvider>
    )
  }
}
