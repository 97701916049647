import React, { useState, useContext, useRef } from 'react'
import { Redirect } from 'react-router-dom'

import { logIn } from '@/services/Auth'
import { AuthContext } from '@/contexts/Auth'

import Alert from '@/components/Alert'
import Button from '@/components/Button'

import SimpleInput from '@/components/Form/SimpleInput'

export default function Login() {

  const [error, setError] = useState('')
  const [formErrors, setFormErrors] = useState(null)

  const { authed, setAuthed } = useContext(AuthContext)

  const refs = {
    username: useRef(''),
    password: useRef(''),
  }

  const context = { refs, formErrors }

  const handleSubmit = e => {
    e.preventDefault()
    setFormErrors(null)
    setError('')
    logIn({
      username: refs.username.current.value.trim(),
      password: refs.password.current.value,
    })
      .then(() => setAuthed(true))
      .catch(response => {
      console.log("Error response:", response);

       // response.data.error
        //  ? setError(response.data.error)
         // : setFormErrors(response.data.errors),
      });
  }

  return authed ? (
    <Redirect
      to={{
        pathname: '/students',
        state: { from: '/login' },
      }}
    />
  ) : (
    <div className="block small">
      <form>
        <Alert text={error} onTimeout={() => setError('')} className="red" />
        <h2>Log in</h2>
        <div className="row">
          <div className="col">
            <SimpleInput label="Email" slug="username" context={context} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <SimpleInput
              type="password"
              label="Password"
              slug="password"
              context={context}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <Button
            text="Forgot your password?"
            className="red-wash"
            to="/forgot-password"
          />
          <Button text="Login" onClick={handleSubmit} className="blue col" />
        </div>
      </form>
    </div>
  )
}
