import React, { useEffect } from 'react'

import './styles.scss'

import { SaveIcon, CloseIcon } from '@/components/icons'
import Button from '@/components/Button'

export default function Form({
  title = '',
  onCancel = () => {},
  onSubmit = () => {},
  onClear = () => {},
  children,
  clearable = false,
  cancelOnEscape = true,
  extraButtons,
  submitText = 'Save',
  cancelText = 'Cancel',
  ...rest
}) {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(e)
  }
  const handleCancel = e => {
    e.preventDefault()
    onCancel(e)
  }
  const handleClear = e => {
    e.preventDefault()
    onClear(e)
  }

  if (cancelOnEscape)
    useEffect(() => {
      document.addEventListener(
        'keydown',
        e => (e.keyCode == 27 ? onCancel() : () => {}),
        false,
      )
    }, [])

  let buttons = [
    <Button key="cancel" text={cancelText} onClick={handleCancel} />,
  ]
    .concat(
      clearable
        ? [
            <Button
              text="Clear"
              key="clear"
              className="red"
              onClick={handleClear}
            >
              <CloseIcon />
            </Button>,
          ]
        : [],
    )
    .concat([
      <Button
        key="submit"
        text={submitText}
        onClick={handleSubmit}
        className="blue"
      >
        <SaveIcon />
      </Button>,
    ])

  if (Array.isArray(extraButtons)) {
    extraButtons.forEach(button => {
      let newButton = button
      if (Array.isArray(button)) newButton = button[0]

      buttons = buttons
        .slice(0, button[1])
        .concat([newButton])
        .concat(buttons.slice([button[1]]))
    })
  } else {
    buttons = [extraButtons].concat(buttons)
  }

  return (
    <form onSubmit={handleSubmit} {...rest}>
      <h2>{title}</h2>
      {children}
      <hr />
      <div className="row flex-start">{buttons}</div>
    </form>
  )
}
