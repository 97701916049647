import React, { Fragment } from 'react'

import './styles.scss'

export default function HiddenInput({
  slug,
  type = 'text',
  context: { refs, formErrors, initialData },
  ...rest
}) {
  return (
    <Fragment>
      <input
        autoComplete="off"
        type="hidden"
        name={slug}
        ref={refs[slug]}
        defaultValue={initialData && initialData[slug] ? initialData[slug] : ''}
        {...rest}
      />
      {formErrors && formErrors[slug] && formErrors[slug].msg && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
    </Fragment>
  )
}
