import React, { useRef, useEffect, useState } from 'react'

import { getCurrentlyOffered, getById } from '@/services/Courses'

import Button from '@/components/Button'
import { CloseIcon } from '@/components/icons'

import Form from '@/components/Form'
import SimpleInput from '@/components/Form/SimpleInput'
import SelectInput from '@/components/Form/SelectInput'
import HiddenInput from '@/components/Form/HiddenInput'
import DateInput from '@/components/Form/DateInput'

export default function SessionForm({
  initialData = null,
  formErrors = {},
  onSubmit = () => {},
  editCourse = false,
  deleteable = false,
  onDelete = () => {},
  ...rest
}) {
  const [course, setCourse] = useState(null)
  const [allCourses, setAllCourses] = useState([])

  const refs = {
    id: useRef(''),
    courseId: useRef(''),
    began: useRef(''),
    ended: useRef(''),
    fee: useRef(''),
    hours: useRef(''),
  }

  const context = { initialData, refs, formErrors }

  useEffect(() => {
    if (editCourse) {
      getCurrentlyOffered(1, 50, 'course_name').then(({ courses }) =>
        setAllCourses(
          courses.map(c => {
            c.value = c.id
            c.label = c.course_name
            return c
          }),
        ),
      )
    } else {
      if (!initialData.course && initialData.courseId)
        getById(initialData.courseId).then(course => {
          initialData.course_name = course.course_name
          setCourse(course)
        })
    }
  }, [])

  useEffect(() => {
    if (course) {
      if (refs.courseId.current !== '') refs.courseId.current.value = course.id
      if (refs.fee.current !== '') refs.fee.current.value = course.fee
      if (refs.hours.current !== '') refs.hours.current.value = course.hours
    }
  }, [course])

  const getData = () => {
    const session = {}
    Object.keys(refs).forEach(key => {
      if (refs[key].current.value) session[key] = refs[key].current.value.trim()
    })
    return session
  }

  return (
    <Form
      id="editSession"
      clearable={false}
      onSubmit={() => onSubmit(getData())}
      extraButtons={
        deleteable && (
          <Button
            key="delete"
            className="red"
            text="Delete Session"
            onClick={onDelete}
          >
            <CloseIcon />
          </Button>
        )
      }
      {...rest}
    >
      <div className="row">
        <div className="col">
          {initialData && initialData.id && (
            <HiddenInput slug="id" type="number" context={context} />
          )}
          {editCourse ? (
            <SelectInput
              label="Select Course"
              placeholder="Select Course"
              slug="courseId"
              context={context}
              options={allCourses}
              defaultValue={''}
              onChange={setCourse}
            />
          ) : (
            <SimpleInput
              label="Select Course"
              placeholder="Select Course"
              slug="course_name"
              context={context}
              disabled
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <DateInput slug="began" label="Start Date" context={context} />
        </div>
        <div className="col">
          <DateInput slug="ended" label="End Date" context={context} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleInput
            type="number"
            className="price"
            step=".01"
            label="Cost"
            slug="fee"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput
            type="number"
            label="Hours"
            slug="hours"
            context={context}
          />
        </div>
      </div>
    </Form>
  )
}
