import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { AddIcon, LeadsIcon, DownloadIcon } from '@/components/icons'
import StudentTable from '@/components/DataTable/StudentTable'

import AddToSessionForm from '@/scenes/StudentDetails/AddToSessionForm'

import { StudentProvider } from '@/contexts/Student'

import { saveStudentFormData } from '@/services/LocalStorage'
import { create, update } from '@/services/Students'
import { addStudentToSession, createHomeStudy } from '@/services/Sessions'

import StudentForm from './StudentForm.js'
import StudentSearchBar from './StudentSearchBar.js'
import { getStudentReport } from '@/services/Reports.js'

export default function StudentList() {
  const [newModalVisible, showNewModal] = useState(false)
  const [editModalVisible, showEditModal] = useState(false)

  const [editFormErrors, setEditFormErrors] = useState(null)
  const [newFormErrors, setNewFormErrors] = useState(null)
  const [sessionFormErrors, setSessionFormErrors] = useState(null)

  const [studentUpdated, setStudentUpdated] = useState(null)
  const [editedStudent, setEditedStudent] = useState(null)

  const [addingToCourse, setAddingToCourse] = useState(false)

  const addStudent = student => {
    setNewFormErrors(null)
    saveStudentFormData(student)
    create(student)
      .then(student => {
        showNewModal(false)
        saveStudentFormData(null)
        setStudentUpdated(student)
      })
      .catch(err => err.data.errors && setNewFormErrors(err.data.errors))
  }

  const addToSession = data => {
    if (data.sessionId) {
      setSessionFormErrors(null)
      addStudentToSession({ ...data, studentId: studentUpdated.id })
        .then(() => window.location.reload(false))
        .catch(err => err.data.errors && setSessionFormErrors(err.data.errors))
    } else {
      setSessionFormErrors(null)
      createHomeStudy(
        Object.assign(data, {
          studentId: studentUpdated.id,
          paymentDate: new Date(),
          submitted: false,
        }),
      )
        .then(() => window.location.reload(false))
        .catch(err => err.data.errors && setSessionFormErrors(err.data.errors))
    }
  }

  const updateStudent = student => {
    setEditFormErrors(null)
    update(student)
      .then(student => setStudentUpdated(student))
      .catch(err => err.data.errors && setEditFormErrors(err.data.errors))
  }

  return studentUpdated && !addingToCourse ? (
    <Redirect
      to={{
        pathname: '/students/' + studentUpdated.id,
        state: { from: '/students' },
      }}
    />
  ) : (
    <StudentProvider>
      <div className="block">
        <div className="row flex-end">
          <div className="col grow">
            <StudentSearchBar />
          </div>
          <Button to="/students/leads" className="col" text="Show Leads">
            <LeadsIcon />
          </Button>
          <Button
            text="New Student"
            className="col blue"
            onClick={() => showNewModal(true)}
          >
            <AddIcon color="#fff" />
          </Button>
          <Button
            text="Download"
            className="col"
            onClick={() => window.open(getStudentReport(), '_blank')}
          >
            <DownloadIcon />
          </Button>
        </div>

        <StudentTable
          onEdit={student => {
            setEditedStudent(student)
            showEditModal(true)
          }}
        />

        <Modal visible={newModalVisible} onClose={() => showNewModal(false)}>
          <StudentForm
            title="Add New Student"
            onCancel={data => {
              saveStudentFormData(data)
              showNewModal(false)
            }}
            remember={true}
            onSubmit={addStudent}
            onSelectOtherStudent={student => {
              showNewModal(false)
              setEditedStudent(student)
              showEditModal(true)
            }}
            updateLeadInfo={true}
            showAutoComplete={false}
            onNext={student => {
              setAddingToCourse(true)
              addStudent(student)
            }}
            formErrors={newFormErrors}
          />
        </Modal>

        <Modal visible={editModalVisible} onClose={() => showEditModal(false)}>
          <StudentForm
            title="Edit Student"
            initialData={editedStudent}
            onCancel={() => showEditModal(false)}
            onSubmit={updateStudent}
            updateLeadInfo={false}
            formErrors={editFormErrors}
          />
        </Modal>

        <Modal
          size="small"
          visible={studentUpdated && addingToCourse}
          onClose={() => setAddingToCourse(false)}
        >
          <AddToSessionForm
            title={
              studentUpdated
                ? 'Add ' +
                  studentUpdated.first_name +
                  ' ' +
                  studentUpdated.last_name +
                  ' to a session'
                : ''
            }
            onCancel={() => setAddingToCourse(false)}
            onSubmit={addToSession}
            formErrors={sessionFormErrors}
          />
        </Modal>
      </div>
    </StudentProvider>
  )
}
