import { paginationHeaders, get, post, put, apiDelete } from './index.js'

const URL = process.env.API_URL + 'api/sessions'

export const getAll = (page = 1, perPage = 10, sortKey = 'id', sort = 'DESC') =>
  get(URL, paginationHeaders({ page, perPage, sort, sortKey })).then(
    ({ data }) => data,
  )

export const getOnSiteSessions = (
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'DESC',
) =>
  get(
    URL + '/not_home_studies',
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const getHomeStudySessions = (
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'DESC',
) =>
  get(
    URL + '/home_studies',
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const search = (
  query,
  page = 1,
  perPage = 10,
  sortKey = 'id',
  sort = 'DESC',
) =>
  post(
    URL + '/search',
    { query },
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const create = session =>
  new Promise((resolve, reject) =>
    post(URL, session)
      .then(response =>
        response.data.created
          ? resolve(response.data.session)
          : reject(response),
      )
      .catch(err => reject(err)),
  )

export const createHomeStudy = session =>
  new Promise((resolve, reject) =>
    post(URL + '/home_studies', session)
      .then(response =>
        response.data.session_created && response.data.classes_taken_created
          ? resolve(response.data.session)
          : reject(response),
      )
      .catch(err => reject(err)),
  )

export const update = session =>
  new Promise((resolve, reject) =>
    put(URL, session)
      .then(response =>
        response.data.updated
          ? resolve(
              response.data.session.length
                ? response.data.session[0]
                : response.data.session,
            )
          : reject(response),
      )
      .catch(err => reject(err)),
  )

export const getById = (
  id,
  page = 1,
  perPage = 10,
  sortKey = 'students.last_name',
  sort = 'DESC',
) =>
  get(URL + '/' + id, paginationHeaders({ page, perPage, sort, sortKey })).then(
    ({ data }) => data,
  )

export const getByCourseId = (
  courseId,
  hidePast = false,
  page = 1,
  perPage = 20,
  sortKey = 'began',
  sort = 'DESC',
) =>
  get(
    URL + '/courses/' + courseId,
    Object.assign(paginationHeaders({ page, perPage, sort, sortKey }), {
      'X-Hide-Past': hidePast,
    }),
  ).then(({ data }) => data)

export const addStudentToSession = studentSession =>
  new Promise((resolve, reject) =>
    post(
      URL + '/students',
      Object.assign(studentSession, { paymentDate: new Date() }),
    )
      .then(response =>
        response.status >= 200 && response.status < 300
          ? resolve(response.data)
          : reject(response),
      )
      .catch(err => reject(err)),
  )

export const removeStudentFromSession = (sessionId, studentId) =>
  new Promise((resolve, reject) =>
    apiDelete(URL + '/students/' + sessionId + '/' + studentId)
      .then(response =>
        response.data.deleted
          ? resolve(response.data.session)
          : reject(response),
      )
      .catch(err => reject(err)),
  )

export const updateStudentInSession = studentSession =>
  new Promise((resolve, reject) =>
    put(URL + '/students', studentSession)
      .then(response =>
        response.data.updated ? resolve(response.data.data) : reject(response),
      )
      .catch(err => reject(err)),
  )

export const deleteById = id =>
  apiDelete(URL + '/' + id).then(({ data }) => data)
