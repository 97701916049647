import { formatDate } from '@/shared/util'

import { getToken } from '@/services/LocalStorage'

const URL = process.env.API_URL + 'api/reports'

const tokenQuery = `jwt=${getToken()}`

export const getClassSignInSheetUrl = sessionId =>
  `${URL}/sign_in/${sessionId}?${tokenQuery}`

export const getSessionCertificatesUrl = (sessionId, background = false) =>
  `${URL}/certificates/session/${sessionId}${
    background ? `?bg=true&${tokenQuery}` : `?${tokenQuery}`
  }`

export const getCertificateUrl = (classTakenId, background = false) =>
  `${URL}/certificate/${classTakenId}${
    background ? `?bg=true&${tokenQuery}` : `?${tokenQuery}`
  }`

export const getRevenueReportUrl = startDate =>
  `${URL}/billing/${formatDate(
    `${startDate} 00:00:00`,
    'yyyy-mm-dd',
  )}?${tokenQuery}`

export const getTrecReportUrl = (startDate, endDate) =>
  `${URL}/trec/${formatDate(
    `${startDate} 00:00:00`,
    'yyyy-mm-dd',
  )}/${formatDate(`${endDate} 00:00:00`, 'yyyy-mm-dd')}?${tokenQuery}`

export const getLetterUrl = classTakenId =>
  `${URL}/letter/${classTakenId}?${tokenQuery}`

export const getRosterUrl = sessionId =>
  `${URL}/roster/${sessionId}?${tokenQuery}`

export const getLeadReport = courseInterestId =>
  `${URL}/lead_info${
    courseInterestId ? '/' + courseInterestId : ''
  }?${tokenQuery}`

export const getStudentReport = () => `${URL}/students?${tokenQuery}`
