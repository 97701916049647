import React, { useState, useEffect, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router'
import './styles.scss'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { DownloadIcon, AddIcon, EditIcon, CloseIcon } from '@/components/icons'

import { getById, update, deleteById } from '@/services/Students'
import {
  addStudentToSession,
  createHomeStudy,
  updateStudentInSession,
} from '@/services/Sessions'

import StudentSessionTable from '@/components/DataTable/StudentSessionTable'
import { StudentSessionProvider } from '@/contexts/StudentSession'

import StudentDetailSidebar from './Sidebar'
import DocumentList from './DocumentList'
import StudentSessionDocumentList from '@/scenes/SessionDetails/StudentSessionDocumentList'
import StudentSessionStatusForm from '@/scenes/SessionDetails/StudentSessionStatusForm'

import AddToSessionForm from './AddToSessionForm'
import StudentForm from '@/scenes/StudentList/StudentForm'

import {
  uploadStudentDocument,
  uploadStudentSessionDocument,
} from '@/services/Documents'

export default function StudentDetails({
  match: {
    params: { id },
  },
}) {
  const [student, setStudent] = useState(null)
  const [sessions, setSessions] = useState([])

  const [documentSession, setDocumentSession] = useState(null)
  const [studentSession, setStudentSession] = useState(null)

  const [addFormErrors, setAddFormErrors] = useState(null)
  const [editFormErrors, setEditFormErrors] = useState(null)
  const [documentFormErrors, setDocumentFormErrors] = useState(null)

  const [addModalVisible, showAddModal] = useState(false)
  const [editModalVisible, showEditModal] = useState(false)
  const [confirmModalVisible, showConfirmModal] = useState(false)
  const [documentModalVisible, showDocumentModal] = useState(false)
  const [sessionDocumentModalVisible, showSessionDocumentModal] = useState(
    false,
  )
  const [statusModalVisible, showStatusModal] = useState(false)

  const [studentDeleted, setStudentDeleted] = useState(false)

  const [documentListUpdated, setDocumentListUpdated] = useState(null)
  const [sessionDocumentListUpdated, setSessionDocumentListUpdated] = useState(
    null,
  )

  const updateStudent = student => {
    setEditFormErrors(null)
    update(student)
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setEditFormErrors(err.data.errors))
  }

  const deleteStudent = (confirmed = false) => {
    showEditModal(false)
    !confirmed
      ? showConfirmModal(true)
      : deleteById(student.id).then(() => setStudentDeleted(true))
  }

  const addToSession = data => {
    setAddFormErrors(null)
    if (data.sessionId) {
      addStudentToSession(Object.assign(data, { studentId: id }))
        .then(() => window.location.reload(false))
        .catch(err => err.data.errors && setAddFormErrors(err.data.errors))
    } else {
      createHomeStudy(
        Object.assign(data, {
          studentId: id,
          paymentDate: new Date(),
          submitted: false,
        }),
      )
        .then(() => window.location.reload(false))
        .catch(err => err.data.errors && setAddFormErrors(err.data.errors))
    }
  }

  const uploadStudentSessionDoc = doc => {
    setDocumentFormErrors(null)
    uploadStudentSessionDocument(student.id, documentSession.id, doc)
      .then(() => setSessionDocumentListUpdated(Date.now()))
      .catch(err => err.data.error && setDocumentFormErrors(err.data.error))
  }

  const uploadDocument = doc => {
    setDocumentFormErrors(null)
    uploadStudentDocument(id, doc)
      .then(() => setDocumentListUpdated(Date.now()))
      .catch(err => err.data.error && setDocumentFormErrors(err.data.error))
  }

  useEffect(() => {
    getById(id).then(({ student, sessions }) => {
      setStudent(student)
      setSessions(sessions)
    })
  }, [id])

  const studentSessionTableRef = useRef()

  return studentDeleted ? (
    <Redirect
      to={{
        pathname: '/students/',
        state: { from: '/students/' + student.id },
      }}
    />
  ) : (
    <StudentSessionProvider studentId={id}>
      <div className="block">
        {student ? (
          <div className="row space-between">
            <StudentDetailSidebar className="col sidebar" student={student} />

            <div className="col grow main">
              <div className="row space-between">
                <div className="col grow">
                  <div className="row">
                    <div className="col">
                      <h1>Course History</h1>
                    </div>
                  </div>
                  {student && student.stats && (
                    <div className="row flex-start delineated">
                      <div className="col">
                        Total Hours: {student.stats.totalHours}
                      </div>
                      <div className="col">
                        {student.stats.totalCourses} Courses,{' '}
                        {student.stats.totalCoursesPassed} Passed
                      </div>
                      <div className="col">
                        Total Income: ${student.stats.totalIncome}
                      </div>
                    </div>
                  )}
                </div>
                <Button
                  className="col"
                  onClick={() => showDocumentModal(true)}
                  text="Documents"
                >
                  <DownloadIcon />
                </Button>
                <Button
                  onClick={() => showEditModal(true)}
                  className="col"
                  text="Edit"
                >
                  <EditIcon />
                </Button>
                <Button
                  onClick={() => showAddModal(true)}
                  className="col blue"
                  text="Add to Session"
                >
                  <AddIcon />
                </Button>
              </div>
              <div className="row">
                <div className="col">
                  <StudentSessionTable
                    ref={studentSessionTableRef}
                    viewDocuments={(session, classTakenId, status) => {
                      session.status = status
                      session.classTakenId = classTakenId
                      setDocumentSession(session)
                      showSessionDocumentModal(true)
                    }}
                    editStatus={studentSession => {
                      setStudentSession(studentSession)
                      showStatusModal(true)
                    }}
                  />
                </div>
              </div>
            </div>

            <Modal
              size="small"
              visible={addModalVisible}
              onClose={() => showAddModal(false)}
            >
              <AddToSessionForm
                title={
                  'Add ' +
                  student.first_name +
                  ' ' +
                  student.last_name +
                  ' to a session'
                }
                onCancel={() => showAddModal(false)}
                onSubmit={addToSession}
                formErrors={addFormErrors}
              />
            </Modal>

            <Modal
              size="small"
              visible={confirmModalVisible}
              onClose={() => showConfirmModal(false)}
            >
              <div className="row">
                <div className="col">
                  Are you sure you want to delete this student?
                  {!!sessions.length &&
                    ' They are enrolled in ' + sessions.length + ' session(s).'}
                </div>
              </div>
              <div className="row">
                <Button
                  text="Never mind"
                  className="col"
                  onClick={() => {
                    showConfirmModal(false)
                    showEditModal(true)
                  }}
                />
                <Button
                  text="Yes, delete this student and all their data"
                  className="red col"
                  onClick={() => deleteStudent(true)}
                >
                  <CloseIcon />
                </Button>
              </div>
            </Modal>

            <Modal
              size="medium"
              visible={editModalVisible}
              onClose={() => showEditModal(false)}
            >
              <StudentForm
                initialData={student}
                onCancel={() => showEditModal(false)}
                onSubmit={updateStudent}
                updateLeadInfo={true}
                formErrors={editFormErrors}
                extraButtons={
                  <Button
                    key="delete"
                    text="Remove Student"
                    className="red"
                    onClick={() => deleteStudent(false)}
                  >
                    <CloseIcon color="#fff" />
                  </Button>
                }
              />
            </Modal>

            <DocumentList
              className="document-list"
              formErrors={documentFormErrors}
              studentId={id}
              visible={documentModalVisible}
              onClose={() => showDocumentModal(false)}
              onUpload={uploadDocument}
              updated={documentListUpdated}
            />

            {documentSession && (
              <StudentSessionDocumentList
                title={
                  student &&
                  student.first_name +
                    ' ' +
                    student.last_name +
                    ' in ' +
                    documentSession.course.course_name
                }
                className="document-list"
                formErrors={documentFormErrors}
                classTakenId={documentSession && documentSession.classTakenId}
                studentId={student && student.id}
                sessionId={documentSession.id}
                prelicensing={
                  documentSession.course &&
                  documentSession.course.course_name &&
                  documentSession.course.course_name
                    .toLowerCase()
                    .indexOf('prelicensing') !== -1
                }
                passing={
                  documentSession &&
                  documentSession.status &&
                  documentSession.status.toLowerCase() === 'pass' &&
                  (student.first_name !== null ||
                    student.middle_name !== null ||
                    student.last_name !== null)
                }
                visible={sessionDocumentModalVisible}
                onClose={() => showSessionDocumentModal(false)}
                onUpload={uploadStudentSessionDoc}
                updated={sessionDocumentListUpdated}
              />
            )}

            {/* Status Edit Form */}

            <StudentSessionStatusForm
              title="Edit Status"
              visible={statusModalVisible}
              initialData={{ ...studentSession, student }}
              onCancel={() => showStatusModal(false)}
              onClose={() => showStatusModal(false)}
              onSubmit={data => {
                updateStudentInSession(data).then(() => {
                  showStatusModal(false)
                  studentSessionTableRef.current.refreshTable()
                })
              }}
            />
          </div>
        ) : (
          <h3>Loading...</h3>
        )}
      </div>
    </StudentSessionProvider>
  )
}
