import React, { useState } from 'react'

import './styles.scss'

import Button from '@/components/Button'
import { AddIcon } from '@/components/icons'
import LeadTable from '@/components/DataTable/LeadTable'
import Modal from '@/components/Modal'

import LeadForm from './LeadForm'
import ExportButton from './ExportButton'

import { add, replace, remove } from '@/services/Leads'
import { LeadProvider } from '@/contexts/Lead'

import CourseInterestSelection from './CourseInterestSelection'

export default function LeadsList() {
  const [newModalVisible, showNewModal] = useState(false)
  const [editModalVisible, showEditModal] = useState(false)

  const [editedLead, updateEditedLead] = useState(null)

  const [addFormErrors, setAddFormErrors] = useState({})
  const [editFormErrors, setEditFormErrors] = useState({})

  const addLead = lead =>
    add(lead)
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setAddFormErrors(err.data.errors))

  const updateLead = lead => {
    setEditFormErrors(null)
    replace(lead)
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setEditFormErrors(err.data.errors))
  }

  const deleteLead = ({ studentId }) => {
    setEditFormErrors(null)
    remove(studentId)
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setEditFormErrors(err.data.errors))
  }

  return (
    <LeadProvider>
      <div className="block">
        <div className="row flex-end">
          <div className="col grow">
            <CourseInterestSelection />
          </div>
          <ExportButton />
          <Button
            text="New Lead"
            className="col blue"
            onClick={() => showNewModal(true)}
          >
            <AddIcon color="#fff" />
          </Button>
        </div>

        <LeadTable
          onEdit={lead => {
            showEditModal(true)
            updateEditedLead(lead)
          }}
        />

        <Modal
          visible={newModalVisible}
          size="medium"
          onClose={() => showNewModal(false)}
        >
          <LeadForm
            title="Add Lead"
            onSubmit={addLead}
            onCancel={() => showNewModal(false)}
            formErrors={addFormErrors}
          />
        </Modal>

        <Modal
          visible={editModalVisible}
          size="medium"
          onClose={() => showEditModal(false)}
        >
          <LeadForm
            title="Edit Lead"
            initialData={editedLead}
            onSubmit={updateLead}
            onCancel={() => showEditModal(false)}
            onDelete={deleteLead}
            formErrors={editFormErrors}
          />
        </Modal>
      </div>
    </LeadProvider>
  )
}
