import React from 'react'

import { getHomeStudySessions } from '@/services/Sessions'

import DataTableProvider from './DataTable'

const HomeStudySessionContext = React.createContext([[], () => {}])

const HomeStudySessionProvider = ({ children }) =>
  DataTableProvider({
    context: HomeStudySessionContext,
    getAll: getHomeStudySessions,
    search: () => {},
    processResponse: ({ count, sessions }) => ({
      count,
      data: sessions,
    }),
    children,
  })

export { HomeStudySessionContext, HomeStudySessionProvider }
