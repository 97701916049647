import { paginationHeaders, get, post, put } from './index.js'

const URL = process.env.API_URL + 'api/leads'

export const getAll = (
  page = 1,
  perPage = 10,
  sortKey = 'date_collected',
  sort = 'DESC',
) =>
  get(URL, paginationHeaders({ page, perPage, sort, sortKey })).then(
    ({ data }) => data,
  )

export const getById = id => get(URL + '/' + id).then(({ data }) => data)

export const search = (
  query,
  page = 1,
  perPage = 10,
  sortKey = 'date_collected',
  sort = 'DESC',
  courseInterests = [],
) =>
  post(
    URL + '/search',
    { query: courseInterests.length ? courseInterests : query },
    paginationHeaders({ page, perPage, sort, sortKey }),
  ).then(({ data }) => data)

export const replace = lead =>
  post(URL, lead).then(({ data: { lead } }) => lead)

export const add = lead => put(URL, lead).then(({ data: { lead } }) => lead)

export const remove = studentId => replace({ studentId, courseInterests: [] })
