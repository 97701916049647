import React, { useRef, useEffect, useState, useContext } from 'react'

import { getById } from '@/services/Courses'

import { CourseSessionContext } from '@/contexts/CourseSession'

import Button from '@/components/Button'

import { CloseIcon } from '@/components/icons'

import Form from '@/components/Form'
import SimpleInput from '@/components/Form/SimpleInput'
import SelectInput from '@/components/Form/SelectInput'
import HiddenInput from '@/components/Form/HiddenInput'
import Checkbox from '@/components/Form/Checkbox'

export default function CourseForm({
  initialData = null,
  title = '',
  formErrors = {},
  onCancel = () => {},
  onSubmit = () => {},
  onDelete = () => {},
  deleteable = false,
  ...rest
}) {
  const [course, updateCourse] = useState(null)

  const { totalRows } = useContext(CourseSessionContext)

  const refs = {
    id: useRef(''),
    course_name: useRef(''),
    certificate_name: useRef(''),
    tn_course_number: useRef(''),
    hours: useRef(''),
    fee: useRef(''),
    available_spots: useRef(''),
    course_type: useRef(''),
    currently_offered: useRef(true),
  }

  const context = { initialData, refs, formErrors }

  useEffect(() => {
    if (course && course.id)
      getById(course.id).then(({ course }) => updateCourse(course))
  }, [course])

  const getData = () => {
    const course = {}
    Object.keys(refs).forEach(key => {
      if (refs[key].current.value) course[key] = refs[key].current.value.trim()
    })
    return course
  }

  return (
    <Form
      id="courseForm"
      clearable={false}
      onSubmit={() => onSubmit(getData())}
      onCancel={() => onCancel(getData())}
      title={title}
      extraButtons={
        deleteable &&
        (totalRows > 0 ? (
          <Button
            key="delete"
            disabled
            className="red"
            text="Cannot delete a course with sessions"
          >
            <CloseIcon />
          </Button>
        ) : (
          <Button
            key="delete"
            text="Delete Course"
            className="red"
            onClick={onDelete}
          >
            <CloseIcon color="#fff" />
          </Button>
        ))
      }
      {...rest}
    >
      <div className="row">
        <div className="col">
          {initialData && initialData.id && (
            <HiddenInput slug="id" type="number" context={context} />
          )}
          <SimpleInput
            label="Course Name"
            slug="course_name"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput
            label="Certificate Name"
            slug="certificate_name"
            context={context}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleInput
            label="Course #"
            type="number"
            slug="tn_course_number"
            context={context}
          />
        </div>
        <div className="col">
          <SelectInput
            label="Course Type"
            slug="course_type"
            options={['On location', 'Home study']}
            context={context}
          />
        </div>
        <div className="col">
          <Checkbox
            label="Currently offered"
            slug="currently_offered"
            context={context}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleInput
            type="number"
            label="Hours"
            slug="hours"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput
            type="number"
            className="price"
            step=".01"
            label="Cost"
            slug="fee"
            context={context}
          />
        </div>
        <div className="col">
          <SimpleInput
            type="number"
            label="Available Spots"
            slug="available_spots"
            context={context}
          />
        </div>
      </div>
    </Form>
  )
}
