import React from 'react'

import SelectInput from './SelectInput'

export default props => (
  <SelectInput
    label="Gender"
    options={[
      { value: 'M', label: 'Male' },
      { value: 'F', label: 'Female' },
      { value: 'O', label: 'Other' },
    ]}
    {...props}
  />
)
