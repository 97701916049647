import React, { Fragment, useContext } from 'react'
import { NavLink } from 'react-router-dom'

import './styles.scss'

import Logo from '@/components/Logo'
import MenuItem from './MenuItem'

import { AuthContext } from '@/contexts/Auth'

import {
  CoursesIcon,
  StudentsIcon,
  HomeStudyIcon,
  CloseIcon,
  TableIcon,
  PrintIcon,
} from '@/components/icons'

export default function Header() {
  const { authed } = useContext(AuthContext)

  return (
    <ul id="header">
      <li id="logo">
        <NavLink to="/students">
          <Logo />
        </NavLink>
      </li>
      {!authed && <MenuItem label="Log in" path="/login" />}
      {authed && (
        <Fragment>
          <MenuItem label="Students" path="/students">
            <StudentsIcon />
          </MenuItem>
          <MenuItem label="Sessions" path="/sessions">
            <CoursesIcon />
          </MenuItem>
          <MenuItem label="Courses" path="/courses">
            <TableIcon />
          </MenuItem>
          <MenuItem label="Home Studies" path="/home-studies">
            <HomeStudyIcon />
          </MenuItem>
          <MenuItem label="Reports" path="/reports">
            <PrintIcon />
          </MenuItem>
          <MenuItem label="Log out" path="/logout">
            <CloseIcon />
          </MenuItem>
        </Fragment>
      )}
    </ul>
  )
}
