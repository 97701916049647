import React, { Fragment, useState, useEffect } from 'react'

import { CloseIcon } from '@/components/icons'

import './styles.scss'

import { search } from '@/services/Students'

export default function StudentAutoComplete({
  label = '',
  slug = '',
  className = '',
  readOnly = false,
  context: { refs, formErrors, initialData },
}) {
  const [suggestionsVisible, showSuggestions] = useState(true)
  const [suggestions, setSuggestions] = useState([])
  const [query, setQuery] = useState('')

  const [student, setStudent] = useState(null)

  const autocomplete = () => {
    if (query && query.length >= 2 && !student) {
      search(query).then(({ students }) => {
        setSuggestions(students)
        showSuggestions(true)
      })
    }
  }

  useEffect(() => {
    let student = {}
    if (initialData) {
      let data = initialData
      if (initialData.student) {
        student = initialData.student
        if (!student.id && data.id) student.id = data.id
      } else if (data.studentId) {
        student.id = data.studentId
      } else if (data.id) {
        student.id = data.id
      }
      if (data.first_name) student.first_name = data.first_name
      if (data.first_name) student.middle_name = data.middle_name
      if (data.first_name) student.last_name = data.last_name
      setStudent(student)
    }
  }, [initialData])

  const select = s => {
    setQuery(s.first_name + ' ' + s.last_name)
    setStudent(s)
  }

  return (
    <Fragment>
      <label htmlFor="query">{label}</label>
      <div
        className={
          className +
          ' auto ' +
          (student ? ' green ' : '') +
          (readOnly ? ' gray ' : '')
        }
      >
        <input
          autoComplete="off"
          className="student-auto"
          name="query"
          type="text"
          value={student ? student.first_name + ' ' + student.last_name : query}
          onChange={e => setQuery(e.target.value)}
          disabled={readOnly}
          onKeyDown={e => {
            if (e.key == 'Enter') e.preventDefault()
            autocomplete()
          }}
        />
        <input
          autoComplete="off"
          type="hidden"
          name={slug}
          ref={refs[slug]}
          readOnly
          defaultValue={student ? student.id : ''}
        />
        {student && !readOnly && (
          <a
            onClick={() => {
              setQuery('')
              setStudent(null)
            }}
          >
            <CloseIcon />
          </a>
        )}
      </div>
      {formErrors && formErrors[slug] && formErrors[slug].msg && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
      {!student &&
        query &&
        suggestionsVisible &&
        suggestions &&
        suggestions.length > 0 && (
          <ul className="autoSuggestions">
            {suggestions.map(s => (
              <li key={s.id} value={s.id} onClick={() => select(s)}>
                {s.first_name} {s.middle_name} {s.last_name}
              </li>
            ))}
          </ul>
        )}
    </Fragment>
  )
}
