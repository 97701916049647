import React, { Fragment } from 'react'

import DataTable from './index.js'
import { SessionRosterContext } from '@/contexts/SessionRoster'

import StatusTag from '@/components/StatusTag'

const columns = [
  {
    label: 'Name',
    expression: ({ students: { first_name, middle_name, last_name } }) =>
      last_name + ', ' + first_name + ' ' + (middle_name || ''),
    sortKey: 'students.last_name',
    link: ({ students: { id } }) => '/students/' + id,
  },
  {
    label: 'Paid',
    expression: ({ paymentAmount }) => '$' + paymentAmount,
  },
  {
    label: 'Due',
    expression: ({ fee, paymentAmount }) =>
      fee !== null &&
      paymentAmount !== null &&
      fee - paymentAmount >= 0 &&
      '$' + (fee - paymentAmount),
  },
  {
    label: 'Grade',
    expression: ({ status }) => <StatusTag status={status} />,
  },
]

export default ({ viewDocuments = () => {}, ...rest }) => (
  <DataTable
    context={SessionRosterContext}
    editable={true}
    columns={columns.concat([
      {
        label: 'Documents',
        expression: ({ status, id, students }) => (
          <a onClick={() => viewDocuments(students, id, status)}>View</a>
        ),
      },
    ])}
    name="SessionRosterTable"
    {...rest}
  />
)
