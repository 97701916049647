import React, { useEffect, useState } from 'react'

import { CloseIcon } from '@/components/icons'
import './styles.scss'

export default function Alert({
  className = '',
  onTimeout = () => {},
  text = '',
  lifespan = 5000,
}) {
  const [active, setActive] = useState(false)

  const show = () => {
    setActive(true)
    setTimeout(onTimeout, lifespan)
  }

  useEffect(() => {
    if (!!text) show()
  }, [text])

  return (
    <div className={'alert-wrapper ' + (active && !!text ? 'active ' : '')}>
      <div className={className + ' alert'}>
        <CloseIcon className="close-alert" onClick={() => setActive(false)} />
        {text}
      </div>
    </div>
  )
}
