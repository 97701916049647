import React, { useEffect, Fragment } from 'react'

export default function FileInput({
  label = '\u00A0',
  slug,
  value,
  context: { refs, formErrors },
  ...rest
}) {
  useEffect(() => {
    if (value === '') {
      refs[slug].current.value = ''
    } else {
      refs[slug].current.files = value
    }
  }, [value])

  return (
    <Fragment>
      <label htmlFor={slug}>{label}</label>
      <input
        autoComplete="off"
        name={slug}
        type="file"
        ref={refs[slug]}
        {...rest}
      />
      {formErrors && formErrors[slug] && formErrors[slug].msg && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
    </Fragment>
  )
}
