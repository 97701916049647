import React, { useContext } from 'react'

import SearchBar from '@/components/SearchBar'

import { SessionContext } from '@/contexts/Session'

export default function SessionSearchBar() {
  const { setSearchTerm, searchTerm } = useContext(SessionContext)

  return (
    <SearchBar
      onSubmit={setSearchTerm}
      searchTerm={searchTerm}
      placeholder="Search"
    />
  )
}
