import React, { useState, useRef, useEffect, Fragment } from 'react'
import Form from '@/components/Form'
import SimpleInput from '@/components/Form/SimpleInput'
import Checkbox from '@/components/Form/Checkbox'
import MultiSelect from '@/components/Form/MultiSelect'

import { DownloadIcon, UploadIcon, LoadingIcon } from '@/components/icons'
import Modal from '@/components/Modal'

import Button from '@/components/Button'

import { formatDate } from '@/shared/util'

import {
  getAllStudentSessionDocuments,
  getDocumentUrl,
} from '@/services/Documents'

export default function StudentSessionStatusForm({
  title = '',
  initialData = null,
  formErrors = null,
  onSubmit = () => {},
  visible = false,
  ...rest
}) {
  const [statuses, setStatuses] = useState([
    { value: 'Incomplete', className: 'yellow', selected: false },
    { value: 'Pass', className: 'green', selected: false },
    { value: 'Fail', className: 'red', selected: false },
    { value: 'No show', className: 'red', selected: false },
    { value: 'Audit', className: 'purple', selected: false },
  ])

  useEffect(() => {
    if (initialData) {
      if (initialData.status) {
        const status = initialData.status
          ? initialData.status.toLowerCase()
          : 'null'
        let newStatuses = statuses.map(s => {
          s.selected = s.value.toLowerCase() === status
          return s
        })
        setStatuses(newStatuses)
      }
    }
  }, [initialData])

  const getData = () => {
    const status = statuses.find(p => p.selected)
      ? statuses.find(p => p.selected).value
      : ''

    const data = {
      paymentMethod: initialData.paymentMethod,
      status,
      // comments:
      studentId: initialData.student.id,
      fee: initialData.fee,
      paymentAmount: initialData.paymentAmount,
      submitted: initialData.session.submitted,
      sessionId: initialData.session.id,
    }

    return data
  }

  const beganDate = initialData.session?.began
    ? formatDate(initialData.session.began, 'mm/dd/yyyy')
    : 'n/a'
  const endDate = initialData.session?.ended
    ? formatDate(initialData.session.ended, 'mm/dd/yyyy')
    : 'n/a'

  return (
    <Modal size="small" visible={visible} {...rest}>
      {/* // <Modal size="small"> */}
      <Form onSubmit={() => onSubmit(getData())} submitText="Update" {...rest}>
        <h1>Edit Student Session Status</h1>
        <div className="row">
          <div className="col">
            Student Name: {initialData.student.first_name}{' '}
            {initialData.student.last_name}
          </div>
        </div>
        <div className="row">
          <div className="col">
            Course Name: {initialData.session?.course?.course_name}
          </div>
        </div>

        <div className="row">
          <div className="col">
            Dates: {beganDate} - {endDate}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <MultiSelect
              label="Status"
              slug="status"
              multi={false}
              context={{}}
              options={statuses}
              onChange={setStatuses}
            />
          </div>
        </div>
      </Form>
    </Modal>
  )
}
