import React, { useState, Fragment, useEffect } from 'react'

import Button from '@/components/Button'
import { DownloadIcon } from '@/components/icons'

import { getStudentReport } from '@/services/Reports'

import '@/components/Form/styles.scss'

export default function StudentReport() {
  return (
    <Fragment>
      <h2>Student Report</h2>
      <div className="row">
        <div className="col">
          <Button
            text="Download"
            className="col blue"
            onClick={() => window.open(getStudentReport(), '_blank')}
          >
            <DownloadIcon />
          </Button>
        </div>
      </div>
    </Fragment>
  )
}
