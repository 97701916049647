import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import './styles.scss'

import { formatDate } from '@/shared/util'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { AddIcon, DownloadIcon, EditIcon } from '@/components/icons'

import SessionRosterTable from '@/components/DataTable/SessionRosterTable'
import { SessionRosterProvider } from '@/contexts/SessionRoster'

import {
  getById,
  update,
  addStudentToSession,
  updateStudentInSession,
  removeStudentFromSession,
  deleteById,
} from '@/services/Sessions'

import {
  uploadStudentSessionDocument,
  uploadSessionDocument,
} from '@/services/Documents'

import AddStudentToSessionForm from './AddStudentToSessionForm'
import HomeStudyForm from './HomeStudyForm'
import SessionForm from '@/components/Form/SessionForm'
import SessionDocumentList from './SessionDocumentList'
import StudentSessionDocumentList from './StudentSessionDocumentList'
import GoBack from '@/shared/GoBack'

export default function SessionDetails({
  match: {
    params: { id },
  },
}) {
  const [session, setSession] = useState(null)
  const [course, setCourse] = useState(null)
  const [editedStudent, setEditedStudent] = useState(null)
  const [documentStudent, setDocumentStudent] = useState(null)

  const [addFormErrors, setAddFormErrors] = useState(null)
  const [editStudentFormErrors, setEditStudentFormErrors] = useState(null)
  const [editSessionFormErrors, setEditSessionFormErrors] = useState(null)
  const [documentFormErrors, setDocumentFormErrors] = useState(null)

  const [addModalVisible, showAddModal] = useState(false)
  const [editStudentModalVisible, showEditStudentModal] = useState(false)
  const [editSessionModalVisible, showEditSessionModal] = useState(false)
  const [sessionDocumentModalVisible, showSessionDocumentModal] = useState(
    false,
  )
  const [
    studentSessionDocumentModalVisible,
    showStudentSessionDocumentModal,
  ] = useState(false)

  const [sessionDocumentListUpdated, setSessionDocumentListUpdated] = useState(
    null,
  )
  const [studentDocumentListUpdated, setStudentDocumentListUpdated] = useState(
    null,
  )

  const [sessionDeleted, setSessionDeleted] = useState(false)

  useEffect(() => {
    getById(id).then(({ session, course }) => {
      setSession(session)
      setCourse(course)
    })
  }, [id])

  const addStudent = student =>
    addStudentToSession(Object.assign(student, { sessionId: id }))
      .then(() => window.location.reload(false))
      .catch(err => err.data.errors && setAddFormErrors(err.data.errors))

  const updateStudent = student =>
    updateStudentInSession(Object.assign(student, { sessionId: id }))
      .then(() => window.location.reload(false))
      .catch(
        err => err.data.errors && setEditStudentFormErrors(err.data.errors),
      )

  const deleteStudent = student =>
    removeStudentFromSession(id, student.studentId)
      .then(() => window.location.reload(false))
      .catch(
        err => err.data.errors && setEditStudentFormErrors(err.data.errors),
      )

  const deleteSession = () =>
    deleteById(session.id)
      .then(() => setSessionDeleted(true))
      .catch(
        err => err.data.errors && setEditSessionFormErrors(err.data.errors),
      )

  const updateSession = session =>
    update(session)
      .then(() => window.location.reload(false))
      .catch(
        err => err.data.errors && setEditSessionFormErrors(err.data.errors),
      )

  const uploadSessionDoc = doc => {
    setDocumentFormErrors(null)
    uploadSessionDocument(session.id, doc)
      .then(() => setSessionDocumentListUpdated(Date.now()))
      .catch(err => err.data.error && setDocumentFormErrors(err.data.error))
  }

  const uploadStudentSessionDoc = doc => {
    setDocumentFormErrors(null)
    uploadStudentSessionDocument(documentStudent.id, session.id, doc)
      .then(() => setStudentDocumentListUpdated(Date.now()))
      .catch(err => err.data.error && setDocumentFormErrors(err.data.error))
  }

  if (!session || !course) {
    return <p>Loading...</p>
  } else if (sessionDeleted) {
    return (
      <Redirect
        to={{
          pathname: '/sessions/',
          state: { from: '/sessions/' + session.id },
        }}
      />
    )
  } else {
    return (
      <SessionRosterProvider sessionId={id}>
        <div className="block">
          <div className="row">
            <div className="col">
              <GoBack></GoBack>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <h1>{course.course_name}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row flex-start delineated">
                    {session.began && (
                      <div className="col">
                        {formatDate(session.began, 'mm/dd/yyyy')} -{' '}
                        {session.ended
                          ? formatDate(session.ended, 'mm/dd/yyyy')
                          : 'present'}
                      </div>
                    )}
                    <div className="col">${session.fee}</div>
                    <div className="col">{session.schedule}</div>
                    <div className="col">{session.hours} hours</div>
                    {session.teacher && (
                      <div className="col">{session.teacher}</div>
                    )}
                    {session.available_spots && (
                      <div className="col">
                        Class size: {session.available_spots}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Button
              onClick={() => showSessionDocumentModal(true)}
              className="col"
              text="Documents"
            >
              <DownloadIcon />
            </Button>
            <Button
              onClick={() => showEditSessionModal(true)}
              className="col"
              text="Edit"
            >
              <EditIcon />
            </Button>
            {(!course.course_type ||
              course.course_type.toLowerCase().indexOf('home') === -1) && (
              <Button
                onClick={() => showAddModal(true)}
                className="col blue"
                text="Add Student"
              >
                <AddIcon />
              </Button>
            )}
          </div>
          <div className="row">
            <div className="col">
              <SessionRosterTable
                onEdit={student => {
                  showEditStudentModal(true)
                  student.submitted = session.submitted
                  student.student = student.students
                  setEditedStudent(student)
                }}
                viewDocuments={(student, classTakenId, status) => {
                  student.status = status
                  student.classTakenId = classTakenId
                  setDocumentStudent(student)
                  showStudentSessionDocumentModal(true)
                }}
              />
            </div>
          </div>
          <Modal
            size="small"
            visible={addModalVisible}
            onClose={() => showAddModal(false)}
          >
            <AddStudentToSessionForm
              title={
                course.course_name +
                ' ' +
                formatDate(session.began, 'mm/dd/yyyy') +
                ' - ' +
                formatDate(session.ended, 'mm/dd/yyyy')
              }
              course={course}
              onSubmit={addStudent}
              onCancel={() => showAddModal(false)}
              formErrors={addFormErrors}
            />
          </Modal>
          <Modal
            size="small"
            visible={editStudentModalVisible}
            onClose={() => showEditStudentModal(false)}
          >
            <AddStudentToSessionForm
              title={
                course.course_name +
                ' ' +
                formatDate(session.began, 'mm/dd/yyyy') +
                ' - ' +
                formatDate(session.ended, 'mm/dd/yyyy')
              }
              course={course}
              initialData={editedStudent}
              onSubmit={updateStudent}
              onCancel={() => showEditStudentModal(false)}
              formErrors={editStudentFormErrors}
              editStudent={false}
              deleteable={true}
              onDelete={deleteStudent}
            />
          </Modal>
          <Modal
            size="medium"
            visible={editSessionModalVisible}
            onClose={() => showEditSessionModal(false)}
          >
            {course.course_type &&
            course.course_type.toLowerCase().indexOf('home') !== -1 ? (
              <HomeStudyForm
                initialData={session}
                title="Edit Session"
                onSubmit={updateSession}
                onCancel={() => showEditSessionModal(false)}
                formErrors={editSessionFormErrors}
                deleteable={true}
                onDelete={deleteSession}
              />
            ) : (
              <SessionForm
                initialData={session}
                title="Edit Session"
                onSubmit={updateSession}
                onCancel={() => showEditSessionModal(false)}
                formErrors={editSessionFormErrors}
                deleteable={true}
                onDelete={deleteSession}
              />
            )}
          </Modal>

          <SessionDocumentList
            className="document-list"
            formErrors={documentFormErrors}
            sessionId={session.id}
            visible={sessionDocumentModalVisible}
            onClose={() => showSessionDocumentModal(false)}
            onUpload={uploadSessionDoc}
            updated={sessionDocumentListUpdated}
          />

          <StudentSessionDocumentList
            title={
              documentStudent &&
              documentStudent.first_name +
                ' ' +
                documentStudent.last_name +
                ' in ' +
                course.course_name
            }
            className="document-list"
            formErrors={documentFormErrors}
            classTakenId={documentStudent && documentStudent.classTakenId}
            studentId={documentStudent && documentStudent.id}
            sessionId={session.id}
            prelicensing={
              course &&
              course.course_name &&
              course.course_name.toLowerCase().indexOf('prelicensing') !== -1
            }
            passing={
              documentStudent &&
              documentStudent.status &&
              documentStudent.status.toLowerCase() === 'pass' &&
              (documentStudent.first_name !== null ||
                documentStudent.middle_name !== null ||
                documentStudent.last_name !== null)
            }
            visible={studentSessionDocumentModalVisible}
            onClose={() => showStudentSessionDocumentModal(false)}
            onUpload={uploadStudentSessionDoc}
            updated={studentDocumentListUpdated}
          />
        </div>
      </SessionRosterProvider>
    )
  }
}
