import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import DataTable from './index.js'
import { CourseContext } from '@/contexts/Course'

const columns = [
  {
    label: 'Course Name',
    expression: ({ id, course_type, course_name }) => (
      <Fragment>
        <Link to={'/courses/' + id}>{course_name}</Link>
        {course_type && course_type.toLowerCase().indexOf('home') >= 0 && (
          <span className="tag small">HS</span>
        )}
      </Fragment>
    ),
    sortKey: 'course_name',
  },
  {
    label: 'Cost',
    expression: ({ fee }) => fee !== null && fee >= 0 && '$' + fee,
    sortKey: 'fee',
  },
  {
    label: 'Course #',
    expression: ({ tn_course_number }) => tn_course_number,
    sortKey: 'tn_course_number',
  },
  {
    label: 'Hours',
    expression: ({ hours }) => hours,
    sortKey: 'hours',
  },
]

export default props => (
  <DataTable
    editable={true}
    context={CourseContext}
    columns={columns}
    name="CourseTable"
    {...props}
  />
)
