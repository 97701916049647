import React, { Fragment, useState, useEffect } from 'react'

import { formatSSN } from '@/shared/util'

import './styles.scss'

export default function SSNInput({
  label = '\u00A0',
  slug,
  context: { refs, formErrors, initialData },
  ...rest
}) {
  // establish internal state
  const [value, setValue] = useState('')

  // if initialData changes, reflect that value
  useEffect(() => {
    if (initialData && initialData[slug] !== null)
      setValue(formatSSN(initialData[slug]))
  }, [initialData])

  const format = newValue => {
    // if not less than 12 chars and only digits and dashes, ignore change
    if (!/^[\d\-]{0,}$/.test(newValue)) return value

    // if ### or more, add a dash after the 3rd one
    if (/^[\d]{4,}$/.test(newValue))
      newValue = newValue.slice(0, 3) + '-' + newValue.slice(3)
    // if ###-## or more, add a dash after 5th digit
    if (/^\d{3}\-\d{3,}$/.test(newValue))
      newValue = newValue.slice(0, 6) + '-' + newValue.slice(6)
    // if #########, add dashes where they need to go
    if (/^\d{6,9}$/.test(newValue))
      newValue =
        newValue.slice(0, 3) +
        '-' +
        newValue.slice(3, 5) +
        '-' +
        newValue.slice(5)
    return newValue.slice(0, 11)
  }

  const handleChange = e => setValue(format(e.target.value.trim()))

  return (
    <Fragment>
      <label htmlFor={slug}>SSN</label>
      <input
        autoComplete="off"
        type="text"
        name={slug}
        ref={refs[slug]}
        onKeyDown={e => {
          if (e.key == 'Enter') e.preventDefault()
        }}
        value={value}
        onChange={handleChange}
        placeholder="###-##-####"
        {...rest}
      />
      {formErrors && formErrors[slug] && formErrors[slug].msg && (
        <div className="error">{formErrors[slug].msg}</div>
      )}
    </Fragment>
  )
}
